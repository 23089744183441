<template>
  <div>
    <v-card-text>
      <v-row row wrap>
        <v-select :items="['AND', 'OR']" label="Group" v-model='type' class="px-2"
          :rules="[v => !!v || 'Item is required']"
          required
        />

        <div class="px-2">
          <v-btn @click='addSubElem("EXPR")' color="indigo darken-4" dark>Expression</v-btn>
        </div>

        <div class="px-2">
          <v-btn @click='addSubElem("GROUP")' color="deep-purple accent-4" dark>Group</v-btn>
        </div>

        <div class="px-2">
          <v-btn @click='removeThis' color="red darken-4" dark>
            <v-icon left>mdi-cancel</v-icon>Remove
          </v-btn>
        </div>
      </v-row>

      <strong v-if='!name'>ROOT</strong>
    </v-card-text>

    <v-card-text v-if='subElem.length > 0'>
      <draggable v-model='subElem'>
        <div v-for='elem in subElem' v-bind:key='elem.name'>
          <expression-builder v-if='elem.type=="EXPR"' 
            :item='item' 
            :queryType="queryType"
            :name='elem.name'
            @kill='remove'
            @change='updateQuery'
            @sendQuery='sendQuery'
            :parentQueries='subElem'
          />

          <group-builder v-if='elem.type=="GROUP"'
            :item='item'
            :queryType="queryType"
            :name='elem.name'
            @kill='remove'
            @change='updateQuery'
            @sendQuery='sendQuery'
            :parentQueries='subElem'
          />
        </div>
      </draggable>
    </v-card-text>

    <v-card-text v-if='!name'>
      <v-btn @click='sendQuery' class="success">Filter</v-btn>
    </v-card-text>
  </div>
</template>

<script>
import expressionBuilder from './expression-builder.vue'
import draggable from 'vuedraggable'

export default {
  name: 'group-builder',
  components: {
    expressionBuilder,
    draggable,
  },
  props: ['item', 'name', 'queryType'],
  data () {
    return {
      subElem: [],
      type: 'AND',
      subType: '',
      showRemove: false,
    }
  },

  mounted() {
    // this.questions = this.item
  },

  methods: {
    addSubElem: function (elemType) {
      var subElem = {
        type: elemType,
        name: this.randomName(),
        query: ''
      }

      this.subElem.push(subElem)
    },

    updateQuery: function (name, string) {
      // console.log('update: ' + string)
      for (var i = 0; i < this.subElem.length; i++) {
        if (this.subElem[i].name === name) {
          this.subElem[i].query = string
          this.$emit('change', this.name, this.queryString)
          break
        }
      }
    },

    remove: function (name) {
      // console.log('Request kill for ' + name)
      for (var i = 0; i < this.subElem.length; i++) {
        if (this.subElem[i].name === name) {
          this.subElem.splice(i, 1)
          break
        }
      }
    },

    removeThis: function () {
      this.$emit('kill', this.name)
    },

    sendQuery: function () {
      this.$emit('sendQuery', this.queryString)
    },

    randomName: function () {
      var hash = Math.random().toString(36).substr(2)
      return hash
    }
  },

  computed: {
    queryString: function () {
      var string = '"$and":['

      if (this.type === 'OR') {
        string = '"$or":['
      }

      var size = this.subElem.length

      for (var i = 0; i < size; i++) {
        string += '{' + this.subElem[i].query + '}'
        if (i < size - 1) {
          string += ','
        }
      }

      string += ']'

      if (!this.name) {
        string = '{' + string + '}'
      }

      return string
    }
  },

  watch: {
    type: function () {
      // console.log('type changed')
      this.$emit('change', this.name, this.queryString)
    },

    item: function () {
      // console.log('group item changed')
      this.subElem = []
    }
  }
}
</script>