import colors from './colors.json'
  
export default {
    create(input) {
        let filteredData

        if (undefined != input.data) {
            filteredData = this.filterData(input)
        }

        if (input.byPanel) {
            Object.keys(filteredData).forEach(function (key) {
                if (!['_c', 'undefined'].includes(key)) {
                    if (undefined == input.panelCounts[key]) {
                        input.panelCounts[key] = 0
                        Object.keys(filteredData[key]).forEach(function (innerKey) {
                            if (innerKey != '_c') {
                                input.panelCounts[key] += filteredData[key][innerKey]
                            }
                        })
                    }
                }
            })
        }
        /**
         * sometimes we need to override the counts to be used for percentage calculation. 
         * Thus we can assign a count from dependent question to be used.
         */
        if (undefined != input.countFromQuestion) {
            let newInput = {}
            newInput = Object.assign(newInput, input)
            newInput.question = input.countFromQuestion
            newInput.data = input.countFromData

            let filteredCountFromData = this.filterData(newInput)
            input.base = 0

            if (input.byPanel) {
                Object.keys(filteredCountFromData).forEach(k1 => {
                    input.panelCounts[k1] = 0
                    input.question.countFrom.filter.forEach(f => {
                        input.panelCounts[k1] += (undefined != filteredCountFromData[k1][f] ? filteredCountFromData[k1][f] : 0)
                    })
                })
            } else {
                input.question.countFrom.filter.forEach(f => {
                    input.base += filteredCountFromData[f]
                })
            }
        }

        if (undefined == filteredData) {
            return null
        }

        let populatedData = this.populate(filteredData, input)

        if (undefined != input.mergedQuestions) {
            populatedData.merged = []
            input.mergedQuestions.forEach(mQ => {
                let newInput = {}
                newInput = Object.assign(newInput, input)
                newInput.question = mQ.question
                newInput.data = mQ.data

                let filteredDataForMergeQuestion = this.filterData(newInput)
                let p = this.populate(filteredDataForMergeQuestion, newInput)
                populatedData.merged.push({data : p, alias : mQ.alias})
            })
        }
    
        return populatedData
    },

    filterData(input) {
        let filterKeys = []

        input.demoQuestions.forEach(d =>{
            filterKeys.push(d.filter_key)
        })

        let unique = new Map()
        if (undefined == input.data) {
            return unique
        }

        input.data.forEach(function (v) {            
            var valid = false
            
            // By pass for backend filtering
            if (undefined != input.discriminator || (input.frontendFiltering && input.frontendFiltering == true)) {                
                if (undefined !== input.filters && input.filters.length > 0) {
                    var e = []
                    input.filters.forEach(function (f) {
                        var bFound = false
                        for (var i = 1; i <= 10; i++) {
                            for (var j = 0; j < f.value.length; j++) {
                                if (v.id[`demo${i}Key`] === f.key) {
                                    let split = undefined != v.id[`demo${i}Value`] ? v.id[`demo${i}Value`].split("_$$") : [] // separator used for multiselect demo questions
                                    for (let k = 0; k < split.length; k++) {
                                        if (split[k] == f.value[j]) {
                                            bFound = true
                                            break
                                        }
                                    }
                                }
    
                                if (bFound) {
                                    break
                                }
                            }
    
                            if (bFound) {
                                break;
                            }
                        }
                        e.push(!!bFound)
                    })
    
                    valid = e.every(function (t) {
                        return t === true
                    })
                } else {
                    for (var i = 1; i <= 10; i++) {
                        if (filterKeys.indexOf(v.id[`demo${i}Key`]) !=  -1) {
                            valid = true
                            break;
                        }
                    }
    
                    if (!valid) {
                        valid = input.demoQuestions.length == 0
                    }
                }
            } else {
                valid = true
            }

            if (valid && v.id.optionText != undefined) {
                let optionText = v.id.optionText
                let optionFound = false
                let options = undefined != input.question.filterOptions ? input.question.filterOptions : input.question.options
                let matchOptionUsingSurveyToolOptionId = input.question.matchOptionUsingSurveyToolOptionId

                if (undefined != options) {
                    options.forEach(o => {
                        if ((undefined != o.range && (o.range.min <= optionText && o.range.max >= optionText)) || (matchOptionUsingSurveyToolOptionId && o.survey_tool_option_id == v.id.optionId)) {
                            optionText = undefined != o.display_text ? o.display_text : (undefined != o.text ? o.text : optionText)
                            optionFound = true
                        } else if (o.text === optionText || optionText === o.decodedValue) {
                            optionText = undefined != o.display_text ? o.display_text : (undefined != o.text ? o.text : optionText)
                            optionFound = true
                        }
                    })
                    
                    if (!optionFound && input.question.options.length > 0) {
                        optionText = "Others"
                    }
                }

              
                // this will be used for multiple series charts.
                if (undefined != input.discriminator) {
                    let discriminator = input.discriminator + 'Value';

                    if (v.id[discriminator] != undefined) {
                        if (undefined === unique[v.id[discriminator]]) {
                            unique[v.id[discriminator]] = new Map()
                        }

                        if (undefined === unique[v.id[discriminator]][optionText]) {
                            unique[v.id[discriminator]][optionText] = 0
                        }

                        unique[v.id[discriminator]][optionText] += v.count
                    }
                } else {
                    optionText = ['RANK', 'TABLE'].includes(input.question.type) ? `__${v.id.rank}__${optionText}` : optionText
                    
                    if (undefined === unique[optionText]) {
                        unique[optionText] = 0
                    }

                    if (['RANK', 'TABLE'].includes(input.question.type)) {
                        if (v.id && v.id.rank && v.id.rank.length > 0) {
                            unique[optionText] += v.count
                        }
                    } else {
                        unique[optionText] += v.count
                    }
                }
            }
        })

        return unique
    },

    populate(unique, input) {
        let multi = ['CHECKBOX', 'MULTI_TEXTBOX', 'RANK'].includes(input.question.type)
        let xAxisCategories = []
        let yAxisCategories = []
        let seriesData = []

        let sampleSize = 0;
        let questionTotal = 0;
        let seriesMap = new Map()
        // let multiseries = false

        Object.keys(unique).forEach((key, key_i) => {
            var value = unique[key]
            if (value instanceof Map) {
                // multiseries = true
                Object.keys(value).forEach(innerMapKey => {
                    var value = unique[key][innerMapKey]
                    if (value > 0) {
                        if (!xAxisCategories.includes(key)) {
                            xAxisCategories.push(key)
                        }
                        if (undefined == seriesMap[innerMapKey]) {
                            seriesMap[innerMapKey] = { 'name': innerMapKey, 'data': [], 'actual': 0 }
                        }
                        seriesMap[innerMapKey].data.push({ name: key, y: value })
                        seriesMap[innerMapKey].actual += value
                        sampleSize += value
                        questionTotal += value
                    }
                })
            } else if (['RANK', 'TABLE'].includes(input.question.type)) {
                let split = key.split('__');
                
                if (split[1]) {
                    if (!xAxisCategories.includes(split[2])) {
                        xAxisCategories.push(split[2])
                    }

                    if (undefined == seriesMap[split[1]]) {
                        seriesMap[split[1]] = { 'name': split[2], 'data': [] }
                    }

                    seriesMap[split[1]].data.push({ 'name': split[2], 'y': unique[key], 'actual': unique[key] })
                }
            } else {
                if (value > 0) {
                    let toPushObj = {
                        'name': key,
                        'y': value,
                        'data': [ { 'y': value } ],
                        'actual': value
                    }

                    // Tree map specific data
                    if (input.question.chart == "TREEMAP") {
                        let foundOption = input.question.options.find(r => r.text == key)
        
                        if (seriesData.find(r => r.id == foundOption.parent) == undefined) {
                            let length = seriesData.filter(r => r.color != undefined).length
        
                            seriesData.push({ id: foundOption.parent, name: foundOption.parent, color: colors[length] })
                        }

                        toPushObj.id = `${key_i}_${key}`
                        toPushObj.parent = foundOption.parent
                        toPushObj.value = value
                    }

                    xAxisCategories.push(key)
                    seriesData.push(toPushObj)
                    sampleSize += value
                    questionTotal += value
                }
            }
        })
                
        Object.keys(seriesMap).forEach(function (seriesMapKey) {
            if (seriesMapKey != '_c') {
                let name = seriesMapKey

                if (input.question.type == "TABLE") {
                    let foundOption = input.question.originalOptions.find(r => r.decodedValue == seriesMapKey)

                    if (foundOption) {
                        name = foundOption.text
                    }
                }

                seriesData.push({ name, 'data': seriesMap[seriesMapKey].data })
            }
        })

        // assumption is first question will always be demographic questions to be loaded. Else we will get wrong count displayed.
        if ((undefined == input.distinctCount || input.distinctCount == 0) && sampleSize > 0) {
            input.distinctCount = sampleSize
        }

        let hasValueCloseToMax = false
        if (['P', 'PT'].includes(input.valueLabel)) {
            let divider 

            // Overwrite base based on count from
            if (undefined != input.overwriteBase) {
                divider = input.overwriteBase
            } else {
                if (input.frontendFiltering == false && input.question.questionBase) {
                    divider = input.question.questionBase
                } else {
                    divider = multi ? input.distinctCount : (questionTotal && questionTotal != input.distinctCount ? questionTotal : input.distinctCount)
                }
            }

            seriesData.forEach(sd => {    
                // Ignore for TreeMap Parent
                if (sd && sd.data && sd.data.length) {
                    // we need to divide by the respondent count rather then question total.
                    for (var j = 0; j < sd.data.length; j++) {
                        if (input.byPanel) {
                            divider = input.panelCounts[sd.data[j].name]
                        }

                        let percent = Math.round((sd.data[j].y / divider) * 100)

                        if (percent > 100) {
                            percent = 100
                        }
    
                        if (!hasValueCloseToMax && percent > 95) {
                            hasValueCloseToMax = true
                        }

                        sd.y = percent
                        sd.data[j].y = percent
                    }
                }
            })
        }

        if (input.question.chart != "TREEMAP") {
            // Use default sorting from question options
            let sortOrder = []
            input.question.options.forEach(o => { sortOrder.push(undefined != o.display_text ? o.display_text : o.text) })
            xAxisCategories.sort((a, b) => sortOrder.indexOf(a) - sortOrder.indexOf(b))
            seriesData.sort((a, b) => sortOrder.indexOf(a.name) - sortOrder.indexOf(b.name));
            seriesData.forEach(sd => { sd.data.sort((a, b) => sortOrder.indexOf(a.name) - sortOrder.indexOf(b.name)) })
            for (let s_i = 0; s_i < seriesData.length; s_i++) { seriesData[s_i].legendIndex = s_i + 1 }

            if (input.question.chart == "HEATMAP") {
                let tempData = []
    
                for (let y_i = 0; y_i < seriesData.length; y_i++) {
                    yAxisCategories.push(seriesData[y_i].name)

                    // Get Top N Data based on legend
                    if (input.question.limit && (y_i >= input.question.limit)) { break }

                    for (let x_i = 0; x_i < seriesData[y_i].data.length; x_i++) {
                        tempData.push([x_i, y_i, seriesData[y_i].data[x_i].y ])
                    }
                }

                seriesData = tempData
            } else {
                let unsortedData = JSON.parse(JSON.stringify(seriesData))
                let sortedData = JSON.parse(JSON.stringify(seriesData.sort((a, b) => b.y - a.y)))    
                
                // Sort Max to Min for chart by default
                seriesData = (!input.question.sortByLegend && !["STACKEDBAR"].includes(input.question.chart)) ? sortedData : unsortedData

                // Get Top N Data
                if (input.question.limit && input.question.limit > 0) {
                    if (!input.question.sortByLegend || input.question.chart == "STACKEDBAR") {
                        seriesData.length = input.question.limit 
                    } else {
                        let topNUnsortedData = []
                        sortedData = sortedData.slice(0, input.question.limit)
                        
                        for (let x_i = 0; x_i < xAxisCategories.length; x_i++) {
                            let found = sortedData.find(r => r.name == xAxisCategories[x_i])
    
                            if (found) {
                                found.legendIndex = x_i + 1
                                topNUnsortedData.push(found)
                            }
                        }
                        
                        seriesData = topNUnsortedData
                    }
                }
            }
        }
        
        // Overwrite groupLabels
        if (input.question.groupLabels && input.question.groupLabels.length > 0) {
            if (input.question.chart == "STACKEDBAR") {
                for (let sD of seriesData) {
                    let found = input.question.groupLabels.find(l => l.text == sD.name)
                    
                    if (found) { 
                        sD.name = found.displayText 
                    }
                }
            } else if (input.question.chart == "HEATMAP") {
                for (let y_i = 0; y_i < yAxisCategories.length; y_i++) {
                    let found = input.question.groupLabels.find(l => l.text == yAxisCategories[y_i])
                    
                    if (found) { 
                        yAxisCategories[y_i] = found.displayText 
                    }
                }
            }
        }

        // This is need for the data table to be properly displayed.
        // if (!multiseries) {
        //     xAxisCategories = ["Count"]
        //     // .splice(0, xAxisCategories.length, "Count")
        // }
        // if (undefined != input.question.limit || input.question.type == 'RANK') {
        //     seriesData.sort(function (a, b) {
        //         var keyA = a.y
        //         var keyB = b.y
        //         if (keyA < keyB) return 1
        //         if (keyA > keyB) return -1
        //         return 0
        //     })
        // }

        return {
            'xAxisCategories': xAxisCategories,
            'yAxisCategories': yAxisCategories.length == 0 ? undefined : yAxisCategories,
            'seriesData': seriesData,
            'total': questionTotal,
            'multi': multi,
            'hasValueCloseToMax': hasValueCloseToMax,
            'showXAxisLabels' : input.question.showXAxisLabels,
            'chartHeight': input.question.chartHeight
        }
    }
}