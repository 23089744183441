import { DataService } from "../../../services/MobileDataService";

const dataService = new DataService();

export default {
  generateDashboardTabs(data) {
    let dashboardTabs = {}

    data.forEach(chart => {
      if (dashboardTabs[chart.tab] == undefined) {
        dashboardTabs[chart.tab] = []
      }

      dashboardTabs[chart.tab].push(chart)
    })

    return dashboardTabs
  },
  generateChartId(questions) {    
    let dashboardTabs = []
    
    for (let qKey of Object.keys(questions)) {
      dashboardTabs.push({
        chartId: `${qKey}`,
        ...questions[qKey],
      })
    }

    return dashboardTabs
  },
  async init(params) {
    params.questions = undefined

    let res = await dataService.getUserActivityData({ 
      ...params, 
      umUserId: this.$store.state.userProfile.userId
    })

    if (params.getUserIds != undefined && params.getUserIds == true) { 
      return res
    }

    return this.generateChartId(res.data)
  }
}