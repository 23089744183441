<template>  
  <div> 
    <v-expansion-panels :value="panels" multiple accordion tile class="pb-4">
      <v-expansion-panel v-if="questions.length > 0">
        <v-expansion-panel-header><h3 class="title">Data Filter</h3></v-expansion-panel-header>
        <v-expansion-panel-content>
          <MongoQueryBuilder
            :questions="questions"
            @filterResult="toMatchActiveDataQuery => loadDashboard({ toMatchActiveDataQuery: toMatchActiveDataQuery })"
            class="py-4"
            queryType="active"
          />
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    
    <v-card>
      <v-tabs v-model="activeTab" v-show="loaded"
        dark
        background-color="cyan"
        slider-color="yellow"
        show-arrows
        centered
        mandatory
      >
        <v-tab v-for="(chartData, chartKey, c_i) in activityDataTabs"
          :key="`a_tab_${chartKey}_${c_i}`"
          v-on:click="resizeChart"
        >
          {{ chartKey }}
        </v-tab>
  
        <v-tab-item v-for="(chartData, chartKey, c_i) in activityDataTabs" :key="`tab_${chartKey}_${c_i}`" :eager="true">
          <v-card>
            <v-card-text>
              <v-layout row wrap>
                <v-flex v-for="(chart, tc_i) in chartData"
                  :lg12="chart.size == 'large' ? true : false"
                  :lg6="chart.size == 'large' ? false : true"
                  v-show="chart.shown == false ? chart.shown : true"
                  :key="`a_tab_${tc_i}`"
                >
                  <div 
                    :id="chart.chartId" 
                  />

                </v-flex>
              </v-layout>
            </v-card-text>
          </v-card>
        </v-tab-item>
      </v-tabs>

    </v-card>
  </div>
</template>

<script>
import { DataService } from "./../../services/MobileDataService.js";
import ChartProcessor from "./charts/chartProcessor.js"
import ActivityDataProcessor from "./charts/activityData.js"
import MongoQueryBuilder from "./MongoQueryBuilder/main-area.vue";

const dataService = new DataService();

export default {
  components: {
    MongoQueryBuilder
  },
  props: {
    selectedValue: Object
  },
  data() {
    return {
      panels: [ 0 ],
      activeTab: null,
      projects: [],
      activities: [],
      activityDataTabs: {},
      loaded: false,
      questions: [],
    }
  },
  mounted() {
    this.$setLoader();
    dataService.getProject(this.selectedValue).then(data => {
      this.selectedProject = data.project
      this.loadDashboard(data.project)
    })
  },
  methods: {
    async loadDashboard(params) {
      let activeDataToSend = {
        ...this.selectedProject,
        getPassiveData: this.selectedProject.config && this.selectedProject.config.getPassiveData ? this.selectedProject.config.getPassiveData : undefined,
        processAppsUsage: this.selectedProject.config && this.selectedProject.config.getPassiveData ? this.selectedProject.config.getPassiveData : undefined,
        toMatchQuery: params.toMatchActiveDataQuery ? params.toMatchActiveDataQuery : undefined
      }

      this.$setLoader();
      await this.activeDashboardData({ ...activeDataToSend })
      this.$clearLoader();
    },
    async activeDashboardData(dataToSend) {
      let data = await ActivityDataProcessor.init(dataToSend)

      if (this.questions == 0) {
        this.questions = data
      }

      this.activityDataTabs = ActivityDataProcessor.generateDashboardTabs(data)
      this.renderChart(data)
      this.loaded = true
    },
    async renderChart(response){      
      let chartObj = await ChartProcessor.chartDataPopulator(response)

      for (let chart of Object.keys(chartObj.inputData)) {
        if (chartObj.seriesData && chartObj.xAxisCategories && chartObj.inputData[chart]) {
          this.$worker.run(() => 'this.$worker run 1: Function in other thread').then(() => {
            ChartProcessor.renderChart({ chartId: chart, ...chartObj })
          }).catch(e => {
            console.error(e);
          });
        }
      }

      // await ChartProcessor.renderChartInit(response)
    },
    resizeChart() {
      setTimeout(function() {
        window.dispatchEvent(new Event("resize"));
      }, 500);
    },
  },
}
</script>