export default {
	isValidString(text) {
		return (text != undefined && text != null && text != "") ? true : false
	},

	generalDataPopulator(question) {
		let processData = []

		question.data.forEach(o => {			
			if (this.isValidString(o._id) || this.isValidString(o.title)) {
				processData.push({
					name : this.isValidString(o._id) ? o._id : o.title, 
					// TODO: Use for Dual Axes. Refactor one day for general usage
					usage: question.title && question.title.includes("AppsUsage$$") ? o.usage : undefined,
					y: o.count, 
					z: 20 
				})
			}
		})

		return processData
	},

	ageDataPopulator(question) {
		let ageGroup = [
			{ name : "<= 25",  y: 0, z: 20 },
			{ name : "26-35",  y: 0, z: 20 },
			{ name : "36-45",  y: 0, z: 20 },
			{ name : "46-55",  y: 0, z: 20 },
			{ name : ">= 56",  y: 0, z: 20 },
		]

		question.data.forEach(o => {
			if (o._id != null){
				if (o._id <= 25) {
					ageGroup[0].y += o.count
				} else if (o._id >= 26 && o._id <= 35) {
					ageGroup[1].y += o.count
				} else if (o._id >= 36 && o._id <= 45) {
					ageGroup[2].y += o.count
				}	else if (o._id >= 46 && o._id <= 55) {
					ageGroup[3].y += o.count
				} else if (o._id >= 56) {
					ageGroup[4].y += o.count
				}
			}
		})
	
		return ageGroup
  },
  
  getXAxisCat(input){
		let data = {}
		
		Object.keys(input).forEach(key => {	
			input[key].forEach(k => {
				if (data[key] == undefined) {
					data[key] = []
				}

				data[key].push(k.name)
			})
		})

		return data
	},  
}