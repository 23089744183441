<template>
  <v-autocomplete v-if='item'
    v-model="selectedItem"
    :items="item"
    label="Title"
    item-text="title"
    return-object
    :rules="[v => !!v || 'Item is required']"
    required
    class="pl-2"
  />
</template>

<script>
export default {
  name: 'object-explorer',
  props: ['item'],
  data () {
    return {
      selectedItem: '',
      subItem: '',
      id: '',
      data: []
    }
  },

  methods: {
    isObject: function (item) {
      if (typeof item.answer === 'object') {
        return true
      }
      return false
    },

    extractItem: function (item) {
      var isArray = Array.isArray(item)
      if (isArray) {
        if (this.isObject(item[0])) {
          this.subItem = item[0]
        } else {
          this.subItem = null
        }
      } else {
        this.subItem = item
      }
    },

    updateSubItem: function () {
      if (this.selectedItem.length > 0) {
        this.extractItem(this.item[this.selectedItem])
        // console.log('Sub:' + JSON.stringify(this.subItem))
        this.$emit('change', this.selectedItem)
      } else {
        // console.log('Reset subitem')
        this.subItem = null
      }
    },
  },
  
  watch: {
    selectedItem: function () {
      this.updateSubItem()
      this.$emit('change', this.selectedItem)
    },

    item: function () {
      this.selectedItem = ''
      this.updateSubItem()
    }
  },

  created: function () {
    this.id = Math.random().toString(36).substr(2)
  }
}
</script>