<template>
  <div>
    <strong>Expression</strong>


    <div class='card-body d-flex justify-content-center px-0'>
      <v-row>
        <v-col cols="6" class="py-0 my-0">
          <objectExplorer v-bind:item='item' @change='updateValuePath'></objectExplorer>
        </v-col>

        <v-col cols="6" class="py-0 my-0 pl-0">
          <!-- :items="subItemIsObject ? ['=', '>', '<', 'exists'] : ['=', '>', '<', 'exists', 'matches']" -->
          <v-select v-show="false"
            disabled
            :items="['=']"
            label="Operator"
            v-model='operator'
            required
            chips
            :rules="[v => !!v || 'Item is required']"
          />

          <MultiSelect v-if="operator == '='"
            :items="options"
            item-text="displayText"
            itemId="answer"
            label="Option(s)"
            @change="value = $event"
            :rules="[v => v.length > 0 || 'Item is required']"
          />
        </v-col>
      </v-row>

      <v-btn @click='removeThis' small color="red darken-4" class="mx-2" dark>
        <v-icon left>mdi-cancel</v-icon>Remove
      </v-btn>
    </div>
  </div>
</template>

<script>
import objectExplorer from './object-explorer.vue'
import MultiSelect from './../../MultiSelect.vue'

export default {
  name: 'expression-builder',
  components: {
    objectExplorer, MultiSelect
  },
  props: ['item', 'name', 'queryType', 'parentQueries'],
  data () {
    return {
      value: '',
      operator: '=',
      objectValuePath: '',
      groupQuery: '',
      options: [],
    }
  },

  methods: {
    update: function () {
      this.$emit('change', this.name, this.queryString)
    },

    updateValuePath: function (newPath) {
      this.objectValuePath = newPath.key ? newPath.key : newPath
        
      if (newPath.options) {
        this.options = newPath.options
      }

      this.update()
    },

    updateGroupQuery: function (name, string) {
      console.log('group changed ' + string)
      this.groupQuery = string
      this.$emit('change', this.name, this.queryString)
    },

    sendQuery: function () {
      this.$emit('sendQuery', this.queryString)
    },

    removeThis: function () {
      this.$emit('kill', this.name)
    },

    resetOperator: function () {
      this.operator = '='
    }
  },

  computed: {
    queryString: function() {
      // TODO: Find a way to break this into smaller functions 
      let string = ""

      let titleValue = this.objectValuePath.text ? this.objectValuePath.text : this.objectValuePath
      let answerValue = this.value


      if (this.operator == "=") {
        for (let i = 0; i < answerValue.length; i++) {
          string += `{"responses": {"question": "${titleValue}", "answer": "${answerValue[i]}"} }`

          if (i + 1 < answerValue.length) {
            string += `,`
          }
        }

        string = `"$or": [ ${string} ]`
      }

      return string
    }
  },

  watch: {
    operator: function () {
      this.update()
    },

    value: function () {
      this.update()
    }
  },

  beforeCreate: function () {
    this.$options.components['groupBuilder'] = require('./group-builder.vue').default
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .sub-item-container {
    margin: 0;
    display: inline;
  }

  .operator-select {
    margin-right: 5px;
    margin-left: 5px;
  }

  .form-control {
    width: 150px;
    height: 28px;
  }

  .card-header {
    padding: 2px;
    padding-left: 5px;
    font-size: 12px;
    height: 27px;
  }

  .card-body {
    padding: 5px;
  }

  button {
    padding-top: 0;
    padding-left: 2px;
    padding-right: 2px;
  }

  img {
    width: 18px;
    height: 18px;
  }

  .card {
    margin: 5px;
    margin-top: 0;
  }

  select {
    padding: 4px;
    padding-right: 28px;
    font-size: 12px;
    height: 28px;
    text-align-last: center;
  }

  .slide-fade-enter-active {
    transition: all .15s ease;
  }

  .slide-fade-enter {
    transform: translateY(10px);
    opacity: 0;
  }

</style>
