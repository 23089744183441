<template>
  <v-container grid-list-xl fluid tile>
    <v-expansion-panels :value="[0]" multiple accordion>
      <v-expansion-panel>
        <v-expansion-panel-header><h2 class="page-title">Projects</h2></v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-autocomplete
            v-if="projects.length > 0"
            :items="projects"
            item-text="name"
            item-value="_id"
            label="Select"
            hint="Pick a project to show the dashboard"
            persistent-hint
            v-on:change="handleProjectChange"
            return-object
          ></v-autocomplete>
          <div v-else>
            <v-text-field
              value="No Project Found"
              readonly
              hint="You do not have any project"
              persistent-hint
            />
          </div>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>

    <BuyerDashboard v-if="loaded && selectedProject && selectedProject.projectType && selectedProject.projectType == 'BUYER'"
      :selectedValue="selectedProject"
    />

    <MobileDashboard v-if="loaded && selectedProject && selectedProject.projectType && selectedProject.projectType == 'MOBILE'"
      :selectedValue="selectedProject"
    />

    <CensusDashboard v-if="loaded && selectedProject && selectedProject.projectType && selectedProject.projectType == 'CENSUS'"
      :selectedValue="selectedProject"
    />
  </v-container>
</template>

<script>
import { DataService as BuyerDataService } from "./../services/BuyerDataService";
import { DataService as MobileDataService } from "./../services/MobileDataService";
import { DataService as CensusDataService } from "./../services/CensusDataService"
import BuyerDashboard from "./../components/BuyerDashboard/Dashboard.vue"
import MobileDashboard from "./../components/MobileDashboard/Dashboard.vue"
import CensusDashboard from "./../components/CensusDashboard/index.vue"

const mobileDataService = new MobileDataService();
const buyerDataService = new BuyerDataService();
const censusDataService = new CensusDataService()

export default {
  name: "dashboard",
  components: {
    BuyerDashboard, MobileDashboard, CensusDashboard
  },
  data() {
    return {
      projects: [],
      selectedProject: {},
      panels: [0],
      projectType: false,
      loaded: false,
    };
  },
  mounted() {
    this.getProjects();
  },
  methods: {
    getProjects() {
      this.$setLoader();

      mobileDataService.getProjects({
        umUserId: this.$store.state.userProfile._id
      }).then(r => {        
        this.projects.push(...r.projects);
        this.$clearLoader()
      })

      buyerDataService.getProjects({
        userId: this.$store.state.buyerUser.userId
      }).then(r => {     
        this.projects.push(...r);
        this.$clearLoader()
      })

      censusDataService.getProjects({
        userId: this.$store.state.buyerUser.userId
      }).then(r => {
        this.projects.push(...r);
        this.$clearLoader()
      })
    },
    handleProjectChange: async function(selectedValue) {
      this.loaded = false

      this.$nextTick(() => {
        this.selectedProject = selectedValue
        
        this.loaded = true
      })
    },
  }
};
</script>