import Highcharts from 'highcharts'
import loadWordcloud from 'highcharts/modules/wordcloud';
import loadExporting from 'highcharts/modules/exporting';
import loadExportData from 'highcharts/modules/export-data';
import offlineExporting from 'highcharts/modules/offline-exporting'
import highchartsMore from 'highcharts/highcharts-more';
import boost from 'highcharts/modules/boost';
import dataPopulator from "./dataPopulator.js"

loadExporting(Highcharts)
loadWordcloud(Highcharts)
loadExportData(Highcharts)
highchartsMore(Highcharts)
offlineExporting(Highcharts)
boost(Highcharts)

export default {
	async renderChartInit(data) {
		let chartObj = await this.chartDataPopulator(data)

		for (let chart of Object.keys(chartObj.inputData)) {
			if (chartObj.seriesData && chartObj.xAxisCategories && chartObj.inputData[chart]) {
				this.renderChart({ chartId: chart, ...chartObj })
			}
		}
	},

	//process chart data
	async chartDataPopulator(input){
		let d = new Map()

		Object.keys(input).forEach(key => {
			if (d[key] === undefined) {
				d[key] = []
			}
			
			if (key == "age"){
				d[key] = dataPopulator.ageDataPopulator(input[key])
			} else {
				d[key] = dataPopulator.generalDataPopulator(input[key])
			}
		});

		let xAxisCategories = dataPopulator.getXAxisCat(d)

		return {
			seriesData: d, 
			xAxisCategories: xAxisCategories,  
			inputData: input,
		}
	},

	// Chart Config
	renderChart(chartObj) {
		let chartId = chartObj.chartId
		let chartType = chartObj.inputData[chartId].chart ? chartObj.inputData[chartId].chart : "PIE"

		if (chartType === 'PIE') {
			this.renderPieChart(chartObj)
		} else if (chartType === 'BAR') {
			this.renderBarChart(chartObj)
		} else if (chartType === 'HALFDONUT') {
			this.renderHalfDonutChart(chartObj)
		} else if (chartType === 'HORIZONTAL') {
			this.renderHorizontalChart(chartObj)
		} else if (chartType === 'INTRO') {
			this.renderIntroductionChart(chartObj)
		} else if (chartType === 'DUALAXES') {
			this.renderDualAxesChart(chartObj)
		}
	},

	renderTitle(rawChartData) {
		if (rawChartData.displayText != undefined)
			return rawChartData.displayText
		else if (rawChartData.title != undefined) 
			return rawChartData.title
		else if (rawChartData._id != undefined && rawChartData._id.question != undefined) 
			return rawChartData._id.question
		else 
			return "[Error] No Question Text"
	},

	generalChartPopulator(chartObj, chartConfig) {		
		let	chartId = chartObj.chartId
		let rawChartData = chartObj.inputData[chartId]
		let data = chartObj.seriesData[chartId]
	
		var dataSum = 0;

		for (var i = 0; i < data.length; i++) {
			dataSum += data[i].y
		}

		let computedSeries = [{
			name: chartId.toUpperCase(),
			colorByPoint: true,
			data: data,
		}]
	
		if (chartConfig.donutChart) {
			computedSeries[0].innerSize = '50%'
		}

		let yAxisConfig = {
			min: rawChartData.yAxisMin,
			max: rawChartData.yAxisMax,
			allowDecimals: true,
			title: {
				text: rawChartData.yAxisTitle ? rawChartData.yAxisTitle : "",
				useHTML: true,
			},
		}
		
		let xAxisConfig = {
			crosshair: true,
			labels: {
				enabled:  true,
			},
			categories: chartObj.xAxisCategories[chartId],
			title: {
				text: rawChartData.xAxisTitle ? rawChartData.xAxisTitle : "",
				useHTML: true,
			}
		}

		let tooltipConfig = {
			headerFormat: '',
			pointFormat:  '<div style="color:{series.color}">{point.name}</div><center><b>' + '{point.y:.0f}'+'</b></center>',
			footerFormat: '',
			useHTML: true,
		}

		if (rawChartData.chart == "DUALAXES") {
			tooltipConfig = {
				shared: true
			}

			if (rawChartData.yAxisConfig && rawChartData.yAxisConfig.length > 0) {
				computedSeries = []
				yAxisConfig = []

				for (let elementYAxisConfig of rawChartData.yAxisConfig) {
					let newData = []

					data.forEach(row => { newData.push(row[elementYAxisConfig.dataKey]) })

					yAxisConfig.push({
						min: rawChartData.yAxisMin ? rawChartData.yAxisMin : elementYAxisConfig.yAxisMin,
						max: rawChartData.yAxisMax ? rawChartData.yAxisMax : elementYAxisConfig.yAxisMax,
						title: {
							text: elementYAxisConfig.axisTitle,
						},
						opposite: (yAxisConfig.length) > 0  ? true : undefined
					})

					computedSeries.push({
						name: elementYAxisConfig.name,
						type: elementYAxisConfig.chart,
						data: newData,
						yAxis: (computedSeries.length > 0) ? 1 : undefined,
						tooltip: {
							valueSuffix: elementYAxisConfig.tooltipSuffix ? elementYAxisConfig.tooltipSuffix : undefined
						}
					})
				}
				
			} else {
				console.log("Expecting something? Missing Config")
			}
		}
	
		let defaultConfig = {
			chart: {
				type: chartConfig.chart.type,
				plotBackgroundColor: null,
				plotBorderWidth: null,
				events: {
					load: function() {
						var total = this.series[0].data[0].total;

						if (total != undefined && total != null) {
							this.renderer.text(`Total: ${total}`, this.plotLeft, this.plotTop).attr({ zIndex: 5 }).add()
						}
					}
				}
			},
			title: {
				text: this.renderTitle(rawChartData),
				useHTML: true,
			},
			subtitle: {
				text: rawChartData.subtitle ? rawChartData.subtitle : "",
				useHTML: true,
			},
			exporting: {
				enabled: true
			},
			credits: {
				enabled: false
			},
			legend: {
				layout: 'horizontal',
        verticalAlign: 'bottom',
				useHTML: true,
				enabled: (chartConfig.chart.type == 'pie') ? true : false
			},
			series: computedSeries,
			xAxis: xAxisConfig,
			yAxis: yAxisConfig,
			tooltip: tooltipConfig,
			boost: { enabled: true },
			plotOptions: {
				series: {
					dataLabels: {
						allowOverlap: true,
						enabled: true
					},
					turboThreshold: 0,
				},
				columnpyramid: {
					dataLabels:{
						enabled:true,
						formatter:function() {
							var pcnt = (this.y / (rawChartData.divideByCount == false ? 1 : dataSum)) * 100;
							return rawChartData.showPercentage ? Highcharts.numberFormat(pcnt) + '%' : Highcharts.numberFormat(this.y);
						}
					}
				},
				pie: {
					showInLegend: true,
					allowPointSelect: true,
					cursor: 'pointer',
					dataLabels: {
						format: '<b>{point.name}</b>: ' + '{point.percentage:.0f} %',
						style: {
							color: (Highcharts.theme && Highcharts.theme.contrastTextColor) || 'black'
						}
					},
				},
				column: {
					dataLabels:{
						enabled:true,
						formatter:function() {
							var pcnt = (this.y / (rawChartData.divideByCount == false ? 1 : dataSum)) * 100;
							return rawChartData.showPercentage ? Highcharts.numberFormat(pcnt) + '%' : Highcharts.numberFormat(this.y);
						}
					}
				},
				bar :{
					dataLabels:{
						enabled:true,
						formatter:function() {
							var pcnt = (this.y / (rawChartData.divideByCount == false ? 1 : dataSum)) * 100;
							return Highcharts.numberFormat(pcnt) + '%'; 
						}
					}
				},
				area:{
					stacking: 'normal',
					lineColor: '#666666',
					lineWidth: 1,
					marker: {
							lineWidth: 1,
							lineColor: '#666666'
					},
					dataLabels:{
						enabled:true,
						formatter:function() {
							var pcnt = (this.y / (rawChartData.divideByCount == false ? 1 : dataSum)) * 100;
							return Highcharts.numberFormat(pcnt) + '%';
						}
					},
				}
			}
		}
		
		if (chartConfig.useArray) {
			defaultConfig.tooltip.pointFormat = '<div style="color:{point.color}">{point.name}</div><b>{point.y:.0f}</b> ({point.percentage:.1f}%)'
		}
	
		if (chartConfig.halfDonutChart) {
			defaultConfig.plotOptions.pie.startAngle = -90
			defaultConfig.plotOptions.pie.endAngle = 90
			defaultConfig.plotOptions.pie.center = ['50%', '100%']
			defaultConfig.plotOptions.pie.size = "175%"
			defaultConfig.plotOptions.pie.showInLegend = true
		}
	
		Highcharts.chart(chartId , defaultConfig)
	},

	renderDualAxesChart(chartObj) {
		this.generalChartPopulator(chartObj, {
			chart: {
				zoomType: 'xy',
				renderTo: chartObj.chartId
			},
		})
	},

	renderBarChart(chartObj) {
		this.generalChartPopulator(chartObj, {
			chart: {
				type: 'column',
				renderTo: chartObj.chartId
			},
		})
	},
	
	renderPieChart(chartObj) {
		this.generalChartPopulator(chartObj, {
			chart: {
				type: 'pie',
				renderTo: chartObj.chartId,
			},
			useArray: true
		})
	},
	
	renderHalfDonutChart(chartObj) {
		this.generalChartPopulator(chartObj, {
			chart: {
				type: 'pie',
				renderTo: chartObj.chartId
			},
			halfDonutChart: true,
			useArray: true,
		})
	},

	renderHorizontalChart(chartObj) {
		this.generalChartPopulator(chartObj, {
			chart: {
				height: 750,
				type: 'bar',
				renderTo: chartObj.chartId
			},
			horizontalChart: true
		})
	},

	renderIntroductionChart(chartObj) {
		this.generalChartPopulator(chartObj, {
			chart: {
				height: 750,
				type: 'columnpyramid',
				renderTo: chartObj.chartId
			},
			horizontalChart: true
		})
	},
}