<template>
  <v-form ref="form">
    <group-builder :item="objectModel" @sendQuery="sendPost" :queryType="queryType"></group-builder>
  </v-form>
</template>

<script>
import groupBuilder from "./group-builder.vue";

export default {
  components: {
    groupBuilder
  },
  props: {
    queryType: String,
    questions: Array,
  },
  data() {
    return {
      objectModel: {},
      buildedSearch: ""
    };
  },
  mounted() {
    this.objectModel = this.searchObjectGenerator(this.questions);
  },
  methods: {
    sendPost: function(queryString) {
      this.buildedSearch = queryString;

      if (this.$refs.form.validate()) {
        let toSendQuery = JSON.parse(queryString)

        if ((toSendQuery.$and && toSendQuery.$and.length > 0) || (toSendQuery.$or && toSendQuery.$or.length > 0)) {
          this.$emit('filterResult', toSendQuery)
        } else {
          this.$emit('filterResult', undefined)
        }
      }
    },
    updateBuildedSearch: function(builded) {
      this.buildedSearch = builded;
    },
    searchObjectGenerator() {
      let searchBuilder = []

      for (let question of this.questions) {
        let options = []

        for (let option of question.data) {
          options.push({ title: option.title })
        }

        searchBuilder.push({
          title: question.title,
          options: options
        })  
      }

      return searchBuilder
    }
  }
};
</script>