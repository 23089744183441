import CommonDataCreator from "./commonDataCreator";

export default {
    create(input) {
        input.discriminator = input.projectDiscriminator
        let filteredData = CommonDataCreator.filterData(input)

        /**
         * sometimes we need to override the counts to be used for percentage calculation. 
         * Thus we can assign a count from dependent question to be used.
         */
        if (undefined != input.countFromQuestion) {
            let newInput = {}
            newInput = Object.assign(newInput, input)
            newInput.question = input.countFromQuestion
            newInput.data = input.countFromData

            let f1 = CommonDataCreator.filterData(newInput)
            let updatedPanelCounts = new Map()
            Object.keys(f1).forEach(k1 => {
                if (undefined == updatedPanelCounts[k1]) {
                    updatedPanelCounts[k1] = 0
                }

                if (input.question.countFrom.filter.includes('$$BRAND$$')) {
                    updatedPanelCounts[k1] += f1[k1][window.$cookies.get('brand')]
                } else {
                    input.question.countFrom.filter.forEach(f => {
                        updatedPanelCounts[k1] += (undefined != f1[k1][f] ? f1[k1][f] : 0)
                    })
                }
            })

            input.updatedPanelCounts = updatedPanelCounts
        }

        Object.keys(filteredData).forEach(function (key) {
            if (!['_c', 'undefined'].includes(key)) {
                if (undefined == input.panelCounts[key]) {
                    input.panelCounts[key] = 0
                    Object.keys(filteredData[key]).forEach(function (innerKey) {
                        if (innerKey != '_c') {
                            input.panelCounts[key] += filteredData[key][innerKey]
                        }
                    })
                }
            }
        })

        return this.populate(filteredData, input);
    },

    populate(unique, input) {
        var xAxisCategories = []
        var seriesData = []

        // let sampleSize = 0;
        let questionTotal = 0;
        let seriesMap = new Map()
        let hasValueCloseToMax = false
        
        Object.keys(unique).forEach(function (key) {
            var value = unique[key]
            if (value instanceof Map) {
                let divider = undefined != input.updatedPanelCounts && undefined != input.updatedPanelCounts[key] ? input.updatedPanelCounts[key] : input.panelCounts[key]
                Object.keys(value).forEach(function (innerMapKey) {
                    if (innerMapKey != '_c') {
                        let value1 = value[innerMapKey]
                        if (value1 > 0) {
                            if (!xAxisCategories.includes(key.toUpperCase())) {
                                xAxisCategories.push(key.toUpperCase())
                            }

                            let computedKey = `${innerMapKey.toUpperCase()}`
                            if (undefined == seriesMap[computedKey]) {
                                seriesMap[computedKey] = { 'name': computedKey, 'data': [], 'actual': 0 }
                            }

                            let computedValue = Math.round((value1 / divider) * 100)
                            if (!hasValueCloseToMax && computedValue > 95) {
                                hasValueCloseToMax = true
                            }
                            seriesMap[computedKey].data.push({
                                'name': `${key.toUpperCase()}`,
                                'y': computedValue > 100 ? 100 : computedValue
                            })
                            seriesMap[computedKey].actual = value1
                            // sampleSize += value1
                            questionTotal += value1
                        }
                    }
                })
            } else {
                if (value > 0) {
                    xAxisCategories.push(key.toUpperCase())
                    seriesData.push({
                        'name': key.toUpperCase(),
                        'y': value,
                        'data': [{
                            'name': key.toUpperCase(),
                            'y': value
                        }
                        ],
                        'actual': value
                    })
                    questionTotal += value
                }
            }

        })

        // let sortOrder = ["Controlled", "Control", "No video", "DBI", "EXPOSED TO DBI", "Scroll", "TVC", "EXPOSED TO TVC", "DBI + Scroll", "DBI + TVC", "DBI+TVC", "EXPOSED TO DBI & TVC", "DBI + Scroll + TVC"];
        let sortOrder = ["CONTROLLED", "CONTROL", "NO VIDEO", "DBI", "EXPOSED TO DBI", "SCROLL", "TVC", "EXPOSED TO TVC", "DBI + SCROLL", "DBI + TVC", "DBI+TVC", "EXPOSED TO DBI & TVC", "DBI + SCROLL + TVC"];
        Object.keys(seriesMap).forEach(function (seriesMapKey) {
            if (seriesMapKey != '_c') {
                let a = seriesMap[seriesMapKey].data

                // if (a.length != sortOrder.length) {
                //     sortOrder.forEach(so => {
                //         let bFound = false 
                //         a.forEach(d => {
                //             if (d.name === so) {
                //                 bFound = true
                //             }
                //         })

                //         if (!bFound) {
                //             a.push({
                //                 'name': so,
                //                 'y' : null 
                //             })
                //         }
                //     })
                    
                // }
                let series = {
                    'type': 'column',
                    'name': seriesMap[seriesMapKey].name.toUpperCase(),
                    'data': a,
                    // 'data': seriesMap[seriesMapKey].data,
                    'actual': seriesMap[seriesMapKey].actual
                }

                if (['CONTROLLED', 'CONTROL', 'NO VIDEO'].includes(seriesMap[seriesMapKey].name)) {
                    series.color = 'rgb(255,0,0)'
                    // series.type = 'spline'
                    // series.data = [82, 82, 82]
                    // series.marker = {
                    //     lineWidth: 2,
                    //     lineColor: 'rgb(58,131,205)',
                    //     fillColor: 'white'
                    // }
                } else if (['DBI', 'EXPOSED TO DBI'].includes(seriesMap[seriesMapKey].name)) {
                    series.color = 'rgb(114,114,114)'
                } else if (['TVC', 'EXPOSED TO TVC'].includes(seriesMap[seriesMapKey].name)) {
                    series.color = 'rgb(58,131,205)'
                } else if (['DBI + TVC','DBI+TVC', 'EXPOSED TO DBI & TVC'].includes(seriesMap[seriesMapKey].name)) {
                    series.color = 'rgb(53,212,199)'
                }
                seriesData.push(series)
                // }
            }
        })

        // assumption is first question will always be demographic questions to be loaded. Else we will get wrong count displayed.
        // if (undefined == input.distinctCount) {
        //     input.distinctCount = questionTotal
        // }

        if ((undefined == input.distinctCount || input.distinctCount == 0) && questionTotal > 0) {
            input.distinctCount = questionTotal
        }

        // xAxisCategories.sort()
        // let sortOrder = ["CONTROL", "EXPOSED TO DBI", "EXPOSED TO TVC", "EXPOSED TO DBI & TVC"];
        xAxisCategories.sort((a, b) => sortOrder.indexOf(a) - sortOrder.indexOf(b))
        seriesData.forEach(sd => {
            sd.data.sort((a, b) => sortOrder.indexOf(a.name) - sortOrder.indexOf(b.name))
        })
        seriesData.sort((a, b) => sortOrder.indexOf(a.name) - sortOrder.indexOf(b.name));

        if (input.question.text === 'BRAND IMPRESSIONS' || input.question.text === 'BRAND RECALL') {
            let originalSeries = seriesData
            let originalX = xAxisCategories
            xAxisCategories = []
            seriesData = []
            originalSeries.forEach(s => {
                if (s.name != undefined) {
                    xAxisCategories.push(s.name)
                }
            })

            let i = 0
            originalX.forEach(x => {
                let updatedSeriesData = {
                    'name': x,
                    'data': [],
                }

                originalSeries.forEach(s => {
                    updatedSeriesData.data.push(undefined != s.data[i] ? s.data[i] : 0)
                })

                seriesData.push(updatedSeriesData)
                i++
            })
        }

        return {
            'xAxisCategories': undefined != input.question.limit ? xAxisCategories.slice(0, input.question.limit) : xAxisCategories,
            'seriesData': undefined != input.question.limit ? seriesData.slice(0, input.question.limit) : seriesData,
            'total': questionTotal,
            'multi': ['CHECKBOX', 'MULTI_TEXTBOX', 'RANK'].includes(input.question.type),
            'hasValueCloseToMax': hasValueCloseToMax,
            'showXAxisLabels' : input.question.showXAxisLabels
        }
    }
}