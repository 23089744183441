<template>
  <div>
    <strong>Expression</strong>

    <div class='card-body d-flex justify-content-center px-0'>
      <objectExplorer v-bind:item='item' @change='updateValuePath'></objectExplorer>

      <!-- :items="subItemIsObject ? ['=', '>', '<', 'exists'] : ['=', '>', '<', 'exists', 'matches']" -->
      <v-select
        :items="['=', '!=']"
        label="Operator"
        v-model='operator'
        class="px-2"
        required
        :rules="[v => !!v || 'Item is required']"
      />

      <v-autocomplete
        v-if="operator == '=' || operator == '!='"
        :items="options"
        label="Answer"
        item-text="title"
        item-value="title"
        v-model='value'
        class="px-2"
        required
        :rules="[v => !!v || 'Item is required']"
      />

      <v-btn @click='removeThis' small color="red darken-4" dark>
        <v-icon left>mdi-cancel</v-icon>Remove
      </v-btn>
    </div>
  </div>
</template>

<script>
import objectExplorer from './object-explorer.vue'

export default {
  name: 'expression-builder',
  components: {
    objectExplorer
  },
  props: ['item', 'name', 'queryType'],
  data () {
    return {
      value: '',
      operator: '=',
      objectValuePath: '',
      showRemove: false,
      groupQuery: '',
      options: [],
      filterKeyMapping: {
        "Device Brand": "Brand",
        "Operating System": "System Name",
      }
    }
  },

  methods: {
    update: function () {
      this.$emit('change', this.name, this.queryString)
    },

    updateValuePath: function (newPath) {
      this.objectValuePath = newPath.key ? newPath.key : newPath
        
      if (newPath.options) {
        this.options = newPath.options
      }

      this.update()
    },

    updateGroupQuery: function (name, string) {
      console.log('group changed ' + string)
      this.groupQuery = string
      this.$emit('change', this.name, this.queryString)
    },

    sendQuery: function () {
      this.$emit('sendQuery', this.queryString)
    },

    removeThis: function () {
      this.$emit('kill', this.name)
    },

    resetOperator: function () {
      this.operator = '='
    }
  },

  computed: {
    queryString: function() {
      // TODO: Find a way to break this into smaller functions 
      if (this.queryType == "passive") {
        let string = ""

        let titleKey = "title"
        let answerKey = "answer"
    
        let titleValue = this.objectValuePath.title ? this.objectValuePath.title : this.objectValuePath
        let answerValue = this.value

        if (this.filterKeyMapping[titleValue]) {
          titleValue = this.filterKeyMapping[titleValue]
        }

        if (titleValue == "Apps Installed") {
          if (this.operator == "=" || this.operator == "!=") {
            answerValue = (this.operator == "!=") ? `{ "$nin": [ "${answerValue}" ] }` : `"${answerValue}"`
      
            string = `"$elemMatch": { "${titleKey}" : ${answerValue}, "answer" : "true", "shared": true }`
          }
        } else if (titleValue == "7 Days Apps Usage" || titleValue == "30 Days Apps Usage" || titleValue == "90 Days Apps Usage") {
          if (this.operator == "=" || this.operator == "!=") {
            if (titleValue == "7 Days Apps Usage") {
              answerKey += ".sevenDays"
            } else if (titleValue == "30 Days Apps Usage") {
              answerKey += ".oneMonth"
            } else if (titleValue == "90 Days Apps Usage") {
              answerKey += ".threeMonths"
            }
          }  
            
          string = `"$elemMatch": { "${titleKey}" : "${answerValue}", "${answerKey}" : { "$gt": 0 }, "shared": true }`

          // TODO: Might not work for all the cases
          if (this.operator == "!=") {
            string = `"$not": { ${string} }`
          }

        } else {
          if (this.operator == "=" || this.operator == "!=") {
            answerValue = (this.operator == "!=") ? `{ "$nin": [ "${answerValue}" ] }` : `{ "$regex": "^${answerValue}$", "$options": "i" }`
      
            string = `"$elemMatch": { "${titleKey}" : "${titleValue}", "${answerKey}" : ${answerValue}, "shared": true }`
          }
        }

        if (["Gender", "Country", "Marital Status"].includes(titleValue)) {
          string = `"demographics": { ${string} }`
        } else if (["Apps Installed"].includes(titleValue)) {
          string = `"appsInstalled": { ${string} }`
        } else if (["Brand", "System Name", "Primary Carrier Name"].includes(titleValue)) {
          string = `"deviceInfo": { ${string} }`
        } else if (["7 Days Apps Usage", "30 Days Apps Usage", "90 Days Apps Usage"].includes(titleValue)) {
          string = `"appsUsage": { ${string} }`
        }

        return string
      } else if (this.queryType == "active") {
        let string = ""

        let titleKey = "userActivityData.data.title"
        let answerKey = "userActivityData.data.answer"
    
        let titleValue = this.objectValuePath.title ? this.objectValuePath.title : this.objectValuePath
        let answerValue = this.value

        if (this.operator == "=" || this.operator == "!=") {
          answerValue = (this.operator == "!=") ? `{ "$nin": [ "${answerValue}" ] }` : `"${answerValue}"`
    
          string = `"$and": [ {"${titleKey}" : "${titleValue}"}, {"${answerKey}" : ${answerValue} }]`
        }

        return string
      } else {
        console.log("Query building not supported!")

        return undefined
      }
    },
  },

  watch: {
    operator: function () {
      this.update()
    },

    value: function () {
      this.update()
    }
  },

  beforeCreate: function () {
    this.$options.components['groupBuilder'] = require('./group-builder.vue').default
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .sub-item-container {
    margin: 0;
    display: inline;
  }

  .operator-select {
    margin-right: 5px;
    margin-left: 5px;
  }

  .form-control {
    width: 150px;
    height: 28px;
  }

  .card-header {
    padding: 2px;
    padding-left: 5px;
    font-size: 12px;
    height: 27px;
  }

  .card-body {
    padding: 5px;
  }

  button {
    padding-top: 0;
    padding-left: 2px;
    padding-right: 2px;
  }

  img {
    width: 18px;
    height: 18px;
  }

  .card {
    margin: 5px;
    margin-top: 0;
  }

  select {
    padding: 4px;
    padding-right: 28px;
    font-size: 12px;
    height: 28px;
    text-align-last: center;
  }

  .slide-fade-enter-active {
    transition: all .15s ease;
  }

  .slide-fade-enter {
    transform: translateY(10px);
    opacity: 0;
  }

</style>
