<template>
  <div class="mt-5">
    <iframe
      id="myframe"
      width="100%"
      height="700"
      frameborder="0"
      allowFullScreen="true"
      powerbi-settings-filter-pane-enabled="true"
    ></iframe>
  </div>
</template>



<script>
export default {
  name: "dashboard",
  props: {
    selectedValue: Object,
  },
  data() {
    return {};
  },

  mounted() {
    this.getIframeUrl();
  },

  methods: {
    getIframeUrl() {
      document.getElementById("myframe").setAttribute("src", this.selectedValue._id.iframe);
    },
  },
};
</script>


<style scoped>
</style>