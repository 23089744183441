<template>
  <v-expansion-panels :value="panels" multiple accordion tile>
    <v-expansion-panel v-if="$parent.currentProject && $parent.currentProject.brands && $parent.currentProject.brands.length > 0">
      <v-expansion-panel-header><h3 class="title">Brand</h3></v-expansion-panel-header>
      <v-expansion-panel-content>
        <v-select
          v-if="$parent.projects.length > 0"
          @change="handleBrandChange($event)"
          :items="$parent.currentProject.brands"
          item-text="text"
          item-value="text"
          label="Brand"
          hint="Pick a brand to show the dashboard"
          persistent-hint
          v-model="$parent.selectedBrand"
        />
      </v-expansion-panel-content>
    </v-expansion-panel>
    <v-expansion-panel v-show="$parent.filterQuestions.length > 0">
      <v-expansion-panel-header><h3 class="title">Filters</h3></v-expansion-panel-header>
      <v-expansion-panel-content>
        <v-row>
          <v-col v-for="question in $parent.filterQuestions" :key="question._id" cols="12" md="6">
            <MultiSelect
              @change="handleFilterChange($event, question.filter_key)"
              :items="question.options.length > 0 ? question.options : question.filterOptions"
              :item-text="question.displayTextAvailable ? 'display_text' : 'text'"
              :itemId="$parent.currentProject.filterKey"
              :label="undefined != question.display_text ? question.display_text :  question.text"
            />
          </v-col>
        </v-row>
        <v-btn color="success" @click="handleApplyFilter">Apply Filters</v-btn>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
import MultiSelect from "./../MultiSelect.vue";

export default {
  name: "dashboard-filter-widgets",
  data() {
    return {
      panels: [ 0 ]
    };
  },
  components: {
    MultiSelect
  },
  methods: {
    customFilter(item, queryText) {
      const textOne = item._id.projectName.toLowerCase();
      const searchText = queryText.toLowerCase();

      return textOne.indexOf(searchText) > -1;
    },
    handleApplyFilter: function() {
      this.$emit("filtersApplied");
    },
    handleProjectChange: function(selectedValue) {
      if(undefined != selectedValue || null != selectedValue) {
      this.$emit("projectChanged", selectedValue);
      }
    },
    handleFilterChange: function(selectedValue, filterKey) {
      this.$emit("filterChanged", selectedValue, filterKey);
    },
    handleBrandChange: function(selectedValue) {
      this.$emit("brandChanged", selectedValue);
    },
    loadPanels: function() {
      this.panel = [
        this.$parent.projects.length > 1,
        this.$parent.filterQuestions.length > 0
      ];
    }
  }
  // mounted() {
  //   this.panel = [this.$parent.projects.length > 1, this.$parent.filterQuestions.length > 0]
  // }
};
</script>

<style lang="scss" scoped>
.hidden {
  display: none;
}
</style>
