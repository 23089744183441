import Highcharts from 'highcharts'
import loadWordcloud from 'highcharts/modules/wordcloud';
import loadExporting from 'highcharts/modules/exporting';
import loadTreeMap from 'highcharts/modules/treemap';
import loadHeatMap from 'highcharts/modules/heatmap';
import offlineExporting from 'highcharts/modules/offline-exporting'
import loadExportData from 'highcharts/modules/export-data';
import highchartsMore from 'highcharts/highcharts-more'

import CommonDataCreator from "./commonDataCreator";
import CampaignDataCreator from './campaignDataCreator';

import { getColor } from './colors'

// import colors from './colors.json'

Highcharts.getOptions().colors = getColor()

loadExporting(Highcharts)
loadWordcloud(Highcharts)
loadExportData(Highcharts)
loadTreeMap(Highcharts)
loadHeatMap(Highcharts)
offlineExporting(Highcharts)
highchartsMore(Highcharts)

let debug = false
let distinctCount, discriminatorQuestionUsed, overrideChartConfig
let loadedData, appliedFilters
let questions, loadedProject, demoQuestions, panelCounts, demoQuestionsList

export default {
  destroy() {
    for (let chart of Highcharts.charts) { if (chart) { chart.destroy() } }
  },

  clearCount() {
    distinctCount = undefined
    overrideChartConfig = false
    discriminatorQuestionUsed = false
    panelCounts = new Map()
  },

  setData(data, project) {
    if (data.debug) {
      debug = data.debug
      console.log(data)
    }

    distinctCount = undefined
    loadedData = data
    loadedProject = project

    questions = new Map()
    demoQuestions = []
    demoQuestionsList = []

    project.questions.forEach(q => {
      if (undefined == questions[q.text]) {
        questions[q.text] = q
        questions[q.text].questionBase = data.questionBase[q.text]
      }

      if (q.demographic) {
        demoQuestions.push(q)
        demoQuestionsList.push(q.text)
      }
    })

    // if demoQuestions not exists, assume the first question is the demo question
    if (demoQuestions.length == 0 && demoQuestionsList.length == 0 && project.questions.length > 0) {
      demoQuestions.push(project.questions[0])
      demoQuestionsList.push(project.questions[0].text)
    }

    appliedFilters = []
    panelCounts = new Map()
  },

  getDistinctCount() {
    return distinctCount
  },

  renderChart(questionElement, filters) {
    appliedFilters = filters
    if (undefined != filters && filters.length > 0) {
      demoQuestions.forEach(d => {
        appliedFilters.forEach(f => {
          if (f.key === d.filter_key && d.discriminator) {
            discriminatorQuestionUsed = true
          }
        })
      })
    }

    // TODO: Hard coded now, need to create factory after this
    if (questionElement.chart != 'WORDCLOUD' && (loadedProject.productType === 'CONCEPT' || discriminatorQuestionUsed || undefined != loadedProject.discriminator)) {
      overrideChartConfig = true
      questionElement.chart = 'BAR'
    }

    if (questionElement.chart === 'PIE') {
      renderPieChart(questionElement)
    } else if (questionElement.chart === 'BAR') {
      renderBarChart(questionElement)
      // } else if (questionElement.chart === 'MULTIBAR') {
      //   renderColumnChart(element)
      // } 
    } else if (questionElement.chart === 'HALFDONUT') {
      renderHalfDonutChart(questionElement)
    } else if (questionElement.chart === 'WORDCLOUD') {
      renderWordCloud(questionElement)
    } else if (questionElement.chart === 'SPIDER') {
      renderSpiderChart(questionElement)
    } else if (questionElement.chart === 'STACKEDBAR') {
      renderStackedBar(questionElement)
    } else if (questionElement.chart === 'TREEMAP') {      
      renderTreeMap(questionElement)
    } else if (questionElement.chart === 'HEATMAP') {      
      renderHeatMap(questionElement)
    } else if (questionElement.chart === 'HORIZONTALSTACKEDBAR') {
			renderHorizontalStackedBar(questionElement)
		}
  },

  exportCharts() {
    let charts = Highcharts.charts;
    // Merge the options
    let options = Highcharts.merge(Highcharts.getOptions().exporting, { type: 'application/pdf', fallbackToExportServer: false });
    // Post to export server
    Highcharts.post(process.env.VUE_APP_HIGHCHART_EXPORT_SERVER, {
      filename: options.filename || `${loadedProject.projectName} charts`,
      type: options.type,
      svg: this.getSVG(charts),
      scale: 1
    });
  },

  getSVG(charts) {
    var svgArr = [],
      top = 0,
      width = 0;

    charts.forEach(chart => {
      if (undefined != chart) {
        var svg = chart.getSVG(),
        // Get width/height of SVG for export

        /*eslint-disable */
        svgWidth = +svg.match(/^<svg[^>]*width\s*=\s*\"?(\d+)\"?[^>]*>/)[1],
        svgHeight = +svg.match(/^<svg[^>]*height\s*=\s*\"?(\d+)\"?[^>]*>/)[1];
        /*eslint-enable */

        svg = svg.replace('<svg', '<g transform="translate(0,' + top + ')" ');
        svg = svg.replace('</svg>', '</g>');

        top += svgHeight;
        width = Math.max(width, svgWidth);

        svgArr.push(svg);
      }
    })

    return '<svg height="' + top + '" width="' + width +
      '" version="1.1" xmlns="http://www.w3.org/2000/svg">' +
      svgArr.join('') + '</svg>';
  },

  processAllDataInCSV() {
    let csvContent = "data:text/csv;charset=utf-8,"

    for (let chart of Highcharts.charts) {
      if (undefined != chart) {
        csvContent += `${chart.title.textStr}\n`
        csvContent += `${chart.getCSV()}\n\n`
      }
    }
    return encodeURI(csvContent);
  }
}

function populateInputForDataCreator(component) {
  let q = component.question
  let split = q.trim().split('-[[') // this is used for some questions where the virtual questions are same and gives the wrong
  let actualQuestion = split.length > 1 ? split[0] : q
  let output = {
    filters: appliedFilters,
    data: loadedData[actualQuestion],
    question: questions[q],
    demoQuestions: demoQuestions,
    projectDiscriminator: loadedProject.discriminator,
    panelCounts: panelCounts,
    distinctCount: distinctCount,
    valueLabel: loadedProject.valueLabel,
    brandKey: loadedProject.brandKey,
  }

  if (undefined != questions[q].countFrom) {
    output.countFromQuestion = questions[questions[q].countFrom.question]
    output.countFromData = loadedData[questions[q].countFrom.question]
  }

  if (undefined != questions[q].mergedQuestions) {
    output.mergedQuestions = []
    questions[q].mergedQuestions.forEach(mQ => {
      output.mergedQuestions.push({
        question: questions[mQ.question],
        data: loadedData[mQ.question],
        alias: mQ.alias
      })
    })
  }
  return output
}

function initChart(component, chartConfig) {
  let chartData
  let input = { ...populateInputForDataCreator(component), frontendFiltering: loadedProject.frontendFiltering }

  if (loadedProject.productType === 'CAMPAIGN') {
    input.discriminator = loadedProject.discriminator
    input.byPanel = true
    chartData = CampaignDataCreator.create(input)
    // chartData = populateChartData(component.question)
  } else {
    if (overrideChartConfig) {
      input.discriminator = loadedProject.discriminator
      input.byPanel = true
    }
    
    chartData = CommonDataCreator.create(input)
  }

  if (chartData == null || chartData.seriesData == null || chartData.seriesData == undefined || chartData.seriesData.length == 0) {
    document.getElementById(component.id).parentElement.style.display = 'none'

    return
  } else {
    document.getElementById(component.id).parentElement.style.display = 'block'
  }

  if (undefined == distinctCount && demoQuestionsList.includes(input.question.text) && input.question.type == "RADIO") {
    distinctCount = input.distinctCount
  }

  /**
   * we need to add missing categories into multi series charts else the bars will not placed correctly as the bars are created based on 
   * indexes. xAxisCategory that has a missing series (Male) will take the series (Male) from the next xAxisCategory thus giving the wrong
   * data on the bars.
   */
  if (undefined == input.question.addMissingCategories || input.question.addMissingCategories) {
    // need to check if we have data for all the categories else multiple series charts with grouping do not render correctly.
    for (var i = 0; i < chartData.seriesData.length; i++) {
      if (chartData.seriesData[i].data.length != chartData.xAxisCategories.length) {
        chartData.xAxisCategories.forEach((xCat, index) => {
          let found = false

          chartData.seriesData[i].data.forEach(d => {
            if (d.name === xCat) {
              found = true
            }
          })

          // no data is found for the xCategory, create and append empty data to the array
          if (!found) {
            chartData.seriesData[i].data.splice(index, 0, { name: xCat, y: null });
          }
        })
      }
    }
  }
  
  // var chartData = populateChartData(component.question)
  let computedSeries = !chartConfig.useArray ? chartData.seriesData : [{
    name: renderTitle(component).text,
    colorByPoint: true,
    data: chartData.seriesData,
    dataSorting: {
      enabled: true
    },
  }]

  if (chartConfig.halfDonutChart) {
    computedSeries[0].innerSize = '50%'
  }
  
  // console.log(`this is data${JSON.stringify(chartData.height)}`)
  let defaultConfig = {
    chart: {
      animation: false,
      height: renderChartHeight(chartData.chartHeight),
      type: chartConfig.chart.type,
      plotBackgroundColor: null,
      plotBorderWidth: null,
      plotShadow: false,
      events: {
        load: function () {
          var total = chartData.total;
          if (undefined != total && undefined != distinctCount && total != distinctCount && !chartData.multi && (undefined != input.question && input.question.type != 'VIRTUAL')) {
            this.renderer.text(
              'Total: ' + total,
              this.plotLeft,
              this.plotTop + 20
            ).attr({
              zIndex: 5
            }).add()
          }
        }
      }
    },
    title: renderTitle(component),
    exporting: {
      scale: 1,
      fallbackToExportServer: false,
      chartOptions:{
        title: renderTitle(component)
      },
    },
    series: computedSeries,
    credits: {
      enabled: false
    },
    legend: {
      useHTML: true
    },
    xAxis: {
      labels: {
        enabled: undefined != chartData.showXAxisLabels ? chartData.showXAxisLabels : false
      },
      categories: ((chartData.xAxisCategories && chartData.xAxisCategories.length > 0 && chartData.multi) || overrideChartConfig) ? chartData.xAxisCategories : [''],
      showInLegend: true,
      title: {
        text: ((component.axisTitles) && (undefined != component.axisTitles.xAxis.title)) ? component.axisTitles.xAxis.title : "",
        useHTML: true,
      }
    },
    yAxis: {
      min: 0,
      allowDecimals: false,
      title: {
        text: ((component.axisTitles) && (undefined != component.axisTitles.yAxis.title)) ? component.axisTitles.yAxis.title : "",
        useHTML: true,
      },
      stackLabels: {
        enabled: false,
        style: {
          fontWeight: 'bold',
          color: (Highcharts.defaultOptions.title.style && Highcharts.defaultOptions.title.style.color) || 'gray',
        }
      }
    },
    tooltip: renderTooltip(discriminatorQuestionUsed, loadedProject),
    plotOptions: {
      bar: {
        animation: false,
        dataLabels: {
          allowOverlap: true,
          enabled: true
        },
        showInLegend: undefined == chartConfig.showLegend ? true : chartConfig.showLegend,
        turboThreshold: 2000
      },
      series: {
        // pointWidth: 30,
        animation: false,
        groupPadding: 0,
        dataLabels: {
          allowOverlap: true,
          enabled: true
        },
        showInLegend: undefined == chartConfig.showLegend ? true : chartConfig.showLegend,
        turboThreshold: 2000
      },
      pie: {
        allowPointSelect: true,
        cursor: 'pointer',
        dataLabels: {
          format: '<b>{point.name}</b>: ' + (loadedProject.valueLabel === 'PT' ? '{point.options.actual}, ' : '') + '{point.percentage:.0f} %',
          style: {
            color: (Highcharts.theme && Highcharts.theme.contrastTextColor) || 'black'
          }
        },
      }
    }
  }

  if (chartConfig.halfDonutChart) {
    defaultConfig.plotOptions.pie.startAngle = -90
    defaultConfig.plotOptions.pie.endAngle = 90
    defaultConfig.plotOptions.pie.center = ['50%', '100%']
    defaultConfig.plotOptions.pie.size = "175%"
    defaultConfig.plotOptions.pie.showInLegend = true
  }
  
  if (input.question.chart === 'STACKEDBAR' || input.question.chart === 'HORIZONTALSTACKEDBAR') {
    let columnOrSeriesConfig = {
      stacking: ['P', 'PT'].includes(loadedProject.valueLabel) ? 'percent' : 'normal',
      dataLabels: {
        enabled: true,
        formatter: function() {
          if (loadedProject.valueLabel === 'P') {
            return Highcharts.numberFormat(this.percentage, 0) + ' %';
          } else if (loadedProject.valueLabel === 'PT') {
            return Highcharts.numberFormat(this.point.actual, 0) + ", " + Highcharts.numberFormat(this.percentage, 0) + ' %';
          } else {
            return Highcharts.numberFormat(this.y, 0);
          }
        }
      }
    }
    
    if (input.question.chart === 'STACKEDBAR') {
      defaultConfig.plotOptions.column = columnOrSeriesConfig
    } else if (input.question.chart === 'HORIZONTALSTACKEDBAR') {
      defaultConfig.plotOptions.series = columnOrSeriesConfig
    }
  }

  if (undefined != loadedProject.valueLabel) {
    defaultConfig.plotOptions.series.dataLabels.formatter = function () {
      if (loadedProject.valueLabel === 'P') {
        return Highcharts.numberFormat(this.y, 0) + ' %';
      } else if (loadedProject.valueLabel === 'PT') {
        return Highcharts.numberFormat(this.series.options.actual, 0) + ", " + Highcharts.numberFormat(this.y, 0) + ' %';
      } else {
        return Highcharts.numberFormat(this.y, 0);
      }
    }

    if (['P', 'PT'].includes(loadedProject.valueLabel) && chartData.hasValueCloseToMax) {
      defaultConfig.yAxis.max = 100
    }
  }
  if (chartConfig.useArray) {
    defaultConfig.tooltip.pointFormat = '<div style="color:{point.color}">{point.name}</div><b>{point.y:.0f}</b> ({point.percentage:.1f}%)'
  }

  Highcharts.chart(component.id, defaultConfig)
}

function renderTreeMap(component) {
  let chartData = CommonDataCreator.create({ ...populateInputForDataCreator(component), frontendFiltering: loadedProject.frontendFiltering })

  Highcharts.chart(component.id, {
    series: [{
      type: 'treemap',
      layoutAlgorithm: 'sliceAndDice',
      allowDrillToNode: true,
      levelIsConstant: false,
      levels: [{
        level: 1,
        dataLabels: {
          enabled: true,
          align: 'left',
          verticalAlign: 'top',
          style: {
            fontSize: '15px',
            fontWeight: 'bold'
          }
        },
      }],
      data: chartData.seriesData,
    }],
    plotOptions: {
      series: {
        animation: false
      }
    },
    tooltip: {
      formatter: function () {
        var total = 0;

        for (var i = 0; i < this.series.data.length; i++)
          if (this.series.data[i].node.children.length == 0)
            total+=this.series.data[i].node.val;

        var value = (this.point.node.children.length == 0) ? this.point.options.value : this.point.node.childrenTotal;
        var text = "<b>" + this.key + "</b>: "

        if (loadedProject.valueLabel === 'P' || loadedProject.valueLabel === 'PT') {
          text += ((value / total)*100).toFixed(0)+"%"

          if (loadedProject.valueLabel === "PT") 
            text += ", " + value
        } else {
          text += value;
        }

        return text
      }
    },
    credits: {
      enabled: false
    },
    title: renderTitle(component),
  });
}

function renderBarChart(component) {
  initChart(component, {
    chart: {
      type: 'column'
    },
  })
}

function renderPieChart(component) {
  initChart(component, {
    chart: {
      type: 'pie'
    },
    useArray: true
  })
}

function renderHalfDonutChart(component) {
  initChart(component, {
    chart: {
      type: 'pie'
    },
    halfDonutChart: true,
    useArray: true,
    showLegend: false
  })
}

function renderWordCloud(component) {
  let input = populateInputForDataCreator(component)
  let chartData = CommonDataCreator.create(input)

  if (chartData == null || chartData.seriesData == undefined || chartData.seriesData == null || chartData.seriesData.length == 0) {
    document.getElementById(component.id).parentElement.style.display = 'none'

    return
  }

  let seriesData = {
    'type': 'wordcloud',
    'name': 'Occurances',
    'data': []
  }

  let stats = {
    numbers: []
  }

  chartData.seriesData.forEach(sd => {
    sd.data.forEach(d => {
      if (d.y > 0) {
        seriesData.data.push({
          'name': sd.name,
          'weight': d.y
        })

        if (input.question.showStatisticalData) {
          if (isNaN(sd.name)) {
            input.question.showStatisticalData = false
          } else {
            for (let i = 0; i < d.y; i++) { stats.numbers.push(parseFloat(sd.name)) }
          }
        }
      }
    })
  })

  if (input.question.showStatisticalData && stats.numbers.length > 0) {
    stats.count = stats.numbers.length
    stats.min = Math.min(...stats.numbers)
    stats.max = Math.max(...stats.numbers)
    stats.mean = stats.numbers.reduce((a, b) => a + b) / stats.count
    stats.stdDev = Math.sqrt(stats.numbers.map(x => Math.pow(x - stats.mean, 2)).reduce((a, b) => a + b) / stats.count)
  } else {
    input.question.showStatisticalData = false
  }


  Highcharts.chart(component.id, {
    series: [{
      type: 'wordcloud',
      data: seriesData.data,
      name: 'Occurrences'
    }],
    title: renderTitle(component),
    subtitle: {
      text: (input.question.showStatisticalData) ? `<b>Mean:</b> ${stats.mean.toFixed(2)} <b>Min:</b> ${stats.min} <b>Max:</b> ${stats.max} <b>StdDev:</b> ${stats.stdDev.toFixed(2)}` : undefined,
      useHTML: true,
    },
    credits: {
      enabled: false
    },
    plotOptions: {
      series: {
        animation: false
      }
    }
  });
}

function renderSpiderChart(component) {
  let input = populateInputForDataCreator(component)
  let chartData = CommonDataCreator.create(input)
  if (chartData.seriesData == undefined || chartData.seriesData.length == 0) {
    document.getElementById(component.id).parentElement.style.display = 'none'
  }

  if (input.question.options.length != chartData.xAxisCategories.length) {
    // check which categories are missing
    input.question.options.forEach((o, idx) => {
      if (chartData.xAxisCategories[idx] != o.text) {
        chartData.xAxisCategories.splice(idx, 0, o.text)
      }
    })
  }

  // check if the main question has all the series required. If not, then add
  chartData.xAxisCategories.forEach((c, idx) => {
    if (chartData.seriesData[idx] && chartData.seriesData[idx].name && chartData.seriesData[idx].name != c) {
      chartData.seriesData.splice(idx, 0, { name: c, y: 0, data: [{ y: 0 }] })
    }
  })

  //check if the merged questions have any missing categories
  if (undefined != chartData.merged) {
    chartData.merged.forEach(m => {
      chartData.xAxisCategories.forEach((c, idx) => {
        if (m.data.seriesData[idx] && m.data.seriesData[idx].name && m.data.seriesData[idx].name != c) {
          // Name does not match
          m.data.seriesData.splice(idx, 0, { name: c, y: 0, data: [{ y: 0 }] })
        }
      })
    })
  }

  Highcharts.chart(component.id, {
    chart: {
      animation: false,
      polar: true,
      type: 'line'
    },
    title: {
      text: input.question.display_text,
    },
    xAxis: {
      categories: chartData.xAxisCategories,
      tickmarkPlacement: 'on',
      lineWidth: 0
    },
    yAxis: {
      gridLineInterpolation: 'polygon',
      lineWidth: 2,
      min: 0
    },
    tooltip: {
      shared: true,
      pointFormat: '<span style="color:{series.color}">{series.name}: <b>{point.y:,.0f}</b><br/>'
    },
    legend: {
      align: 'center',
      verticalAlign: 'bottom'
    },
    credits: {
      enabled: false
    },
    series: [
      {
        name: input.question.alias,
        data: chartData.seriesData,
        // data: [51, 75, 30, 45, 80],
        pointPlacement: 'on'
      }, {
        name: chartData.merged[0].alias,
        data: chartData.merged[0].data.seriesData,
        // data: [65, 42, 55, 60, 80],
        pointPlacement: 'on'
      }
    ],
    plotOptions: {
      series: {
        animation: false
      },
    }
  });

}

function renderStackedBar(component) {
  initChart(component, {
    chart: {
      type: 'column'
    },
  })
}

function renderHorizontalStackedBar(component) {
  initChart(component, {
    chart: {
      type: 'bar'
    },
  })
}

function renderHeatMap(component) {
  let chartData = CommonDataCreator.create({ ...populateInputForDataCreator(component), frontendFiltering: loadedProject.frontendFiltering })

  function getPointCategoryName(point, dimension) {
    var series = point.series,
      isY = dimension === 'y',
      axis = series[isY ? 'yAxis' : 'xAxis'];
    return axis.categories[point[isY ? 'y' : 'x']];
  }
  
  Highcharts.chart(component.id, {
    chart: {
      type: 'heatmap',
      marginTop: 80,
      marginBottom: 80,
      plotBorderWidth: 1
    },
    title: renderTitle(component),
    credits: {
      enabled: false
    },
    xAxis: {
      categories: chartData.xAxisCategories,
      labels: {
        style: {
          color: "black"
        }
      }
    },
    yAxis: {
      categories: chartData.yAxisCategories,
      title: null,
      reversed: true,
      labels: {
        style: {
          color: "black"
        }
      }
    },
    colorAxis: {
      min: 0,
      minColor: '#FFFFFF',
      maxColor: Highcharts.getOptions().colors[0]
    },
    legend: {
      align: 'right',
      layout: 'vertical',
      margin: 0,
      verticalAlign: 'top',
      y: 25,
      symbolHeight: 280
    },
    plotOptions: {
      series: {
        dataLabels: {
          formatter: function () {
            let text = '<b>' + this.point.value + ' %</b>'
            return (loadedProject.valueLabel === 'P' || loadedProject.valueLabel === 'PT') ? text : text.replace("%", "")
          }
        }
      }
    },
    tooltip: {
      formatter: function () {
        let text = '<b>' + this.point.value + ' %</b> selected option <b>'+ getPointCategoryName(this.point, 'y') + '</b> for <b>' + getPointCategoryName(this.point, 'x') + '</b>';
        return (loadedProject.valueLabel === 'P' || loadedProject.valueLabel === 'PT') ? text : text.replace("%", "")
      }
    },
    series: [{
      borderWidth: 1,
      data: chartData.seriesData,
      dataLabels: {
        enabled: true,
        color: '#000000'
      }
    }],
  });
}

function renderTitle(component) {
  return {
    text: ((undefined != component.displayText) && !debug) ? component.displayText : component.question,
    useHTML: false
  }
}

function renderTooltip(discriminatorQuestionUsed, loadedProject) {
  return {
    headerFormat: '',
    pointFormat: '<div style="color:{series.color}">{series.name}</div><center><b>' + (discriminatorQuestionUsed && loadedProject.valueLabel === 'PT' ? '{series.options.actual},' : '') + '{point.y:.0f}' + (['P', 'PT'].includes(loadedProject.valueLabel) ? ' %' : '') + '</b></center>',
    footerFormat: '',
    useHTML: true
  }
}

function renderChartHeight(chartHeight) {
  if (chartHeight == "medium") return 600
  else if (chartHeight == "large") return 800
  else return 400
}