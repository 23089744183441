<template>
  <div>
    <dashboard-filter-widgets v-if="useFilterType == 1"
      @filterChanged="handleFilterChange"
      @projectChanged="handleProjectChange"
      @filtersApplied="applyFilters"
      @brandChanged="handleBrandChange"
    />

    <v-expansion-panels :value="panels" multiple accordion tile class="pb-4">
      <v-expansion-panel v-if="useFilterType == 2 && advanceFilterQuestions.length > 0">
        <v-expansion-panel-header><h3 class="title">Custom Data Filter</h3></v-expansion-panel-header>
        <v-expansion-panel-content>
          <MongoQueryBuilder
            @filterResult="toMatchQuery => getDashboardData({ projectId: selectedValue.projectId, toMatchQuery })"
            :questions="advanceFilterQuestions"
            class="py-4"
            queryType="active"
          />
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>

    <div v-bind:class="{ hidden: !invalidProject }">This is an invalid project as no data exists.</div>

    <v-card v-if="dashboardVisible && distinctRespondentCount">
      <v-card-title primary-title>
        <div>
          <div class="headline">{{ distinctRespondentCount }}</div>
          <span class="grey--text">Distinct Respondent Count</span>
        </div>
      </v-card-title>
    </v-card>

    <v-row class="px-3 my-4" v-if="dashboardVisible">
      <div v-if="filterQuestions.length > 0">
        <v-btn class="success my-2" @click="getDashboardData({ projectId: selectedValue.projectId, frontendFiltering: true })" v-if="useFilterType == 2">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <span v-bind="attrs" v-on="on"><v-icon left>mdi-filter</v-icon>Basic Filter</span>
            </template>
            <span>Switch to Basic Filter</span>
          </v-tooltip>
        </v-btn>
        
        <v-btn class="success my-2" @click="getDashboardData({ projectId: selectedValue.projectId })" v-if="useFilterType == 1">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <span v-bind="attrs" v-on="on"><v-icon left>mdi-filter-menu</v-icon>Visual Query Filter</span>
            </template>
            <span>Switch to Visual Query Filter</span>
          </v-tooltip>          
        </v-btn>
      </div>

      <v-btn class="success ma-2" @click="onLoadProjectFiles">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <span v-bind="attrs" v-on="on"><v-icon left>mdi-folder-open</v-icon>File Explorer</span>
          </template>
          <span>Obtain and download files related to the project</span>
        </v-tooltip>  
      </v-btn>

      <v-btn class="success my-2" @click="handleProjectChange" v-if="debug">
        Reload
      </v-btn>

      <v-spacer />

      <v-btn class="success ma-2" @click="exportAll({ exportType: 'charts'})">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <span v-bind="attrs" v-on="on"><v-icon left>mdi-chart-areaspline</v-icon>Export All Charts</span>
          </template>
          <span>Export all charts in this project to PDF</span>
        </v-tooltip>  
      </v-btn>

      <v-btn class="success my-2" @click="exportAll({ exportType: 'data' })">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <span v-bind="attrs" v-on="on"><v-icon left>mdi-file-table-box-outline</v-icon>Export All Data</span>
          </template>
          <span>Export all chart data in this project to CSV</span>
        </v-tooltip>  
      </v-btn>
    </v-row>

    <v-tabs v-if="currentProject && currentProject.tabs && dashboardVisible" v-model="active"
      background-color="cyan" slider-color="yellow"
      dark mandatory show-arrows centered
    >
      <v-tab v-for="(tab, tabIndex) in currentProject.tabs" :key="'addTab_'+tabIndex">{{ tab.label }}</v-tab>
      <v-tab-item v-for="(tab, tabIndex) in currentProject.tabs" :key="'addTab_'+tabIndex" 
        :eager="true"
        :transition="false"
        :reverse-transition="false"
      >
        <img :src="tab.content[selectedBrand]" v-if="tab.contentType == 'IMAGE'" class="center" />
      </v-tab-item>

      <v-tab v-for="(cat, catIndex) in categoryQuestions" :key="'tab_'+catIndex" v-on:click="loadTab(catIndex)">{{ cat.name }}</v-tab>
      <v-tab-item v-for="(cat, catIndex) in categoryQuestions" :key="'tab_'+catIndex"
        :eager="true"
        :transition="false"
        :reverse-transition="false"
      >
        <v-card>
          <v-card-text>
            <v-layout row wrap>
              <v-flex v-for="(chartQuestion, qIndex) in cat.questions" :key="'tab_v_card_'+qIndex"
                v-show="chartQuestion.shown"
                :lg12="chartQuestion.width && chartQuestion.width == 'large' ? true : false"
                :lg6="chartQuestion.width && chartQuestion.width == 'large' ? false : true"
                sm12
              >
                <div :key="'chart_' + catIndex + '_' + qIndex"
                  :id="'chart_' + catIndex + '_' + qIndex"
                  ref="chartContainer"
                  class="chart"
                  :data-id="'chart_' + catIndex + '_' + qIndex"
                  :data-tab-index="catIndex"
                  :data-chart="'' + chartQuestion.chart"
                  :data-question="chartQuestion.text"
                  :data-display-text="chartQuestion.display_text"
                />
                <div v-if="chartQuestion.ref_questions.length > 0 || currentProject.showSummaryTab">
                  <div v-for="(refQ, rIndex) in chartQuestion.ref_questions" :key="'refQ_' + rIndex">
                    <b v-if="undefined != refQ.display_text">Q{{rIndex + 1}}: {{refQ.display_text}}</b>
                  </div>
                  <v-textarea
                    label="Summary"
                    hint="Summary"
                    :id="'comment_' + chartQuestion._id"
                    :value="undefined != commentsMap[selectedBrand] && undefined != commentsMap[selectedBrand][chartQuestion._id] ? commentsMap[selectedBrand][chartQuestion._id].comment : undefined"
                  ></v-textarea>
                  <v-btn
                    color="info"
                    @click="handleSaveComment"
                    :data-questionId="chartQuestion._id"
                    :data-id="undefined != commentsMap[selectedBrand] && undefined != commentsMap[selectedBrand][chartQuestion._id] ? commentsMap[selectedBrand][chartQuestion._id]._id : undefined"
                  >Save</v-btn>
                </div>
              </v-flex>
            </v-layout>
          </v-card-text>
        </v-card>
      </v-tab-item>
    </v-tabs>

    <v-dialog v-model="openDownloadDialog">
      <v-card height="90vh">
        <v-card-title>
          <span>Project File Explorer</span>
          <v-spacer />
          <v-btn icon @click="openDownloadDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text v-if="projectFiles == null">
          Hang on, we are looking for your files.
        </v-card-text>
        <v-simple-table v-else-if="projectFiles && projectFiles.length > 0">
          <template v-slot:default>
            <thead>
              <tr>
                <th>File Name</th>
                <th>File Size</th>
                <th class="text-center">Uploaded At</th>
                <th class="text-center">Action</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(row, row_i) in projectFiles" :key="`row_${row_i}`">
                <td>{{ row.fileName }}</td>
                <td>{{ row.fileSize }} KB</td>
                <td class="text-center">{{ row.uploadedAt }}</td>
                <td class="text-center">
                  <v-btn :href="row.filePath" download class="primary" small>Download</v-btn>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
        <v-card-text v-else>
          No files are available for this project.
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-footer padless fixed v-if="loaderProgress < loaderProgressBase" >
      <v-progress-linear height="25">
        <strong>Loading: {{ loaderProgress }}/{{ loaderProgressBase }}</strong>
      </v-progress-linear>
    </v-footer>
  </div>
</template>

<script>
import { DataService } from "./../../services/BuyerDataService";
import DashboardFilterWidgets from "./FiltersWidget.vue";
import dashboard from "./dashboard.js";
import MongoQueryBuilder from "./MongoQueryBuilder/main-area.vue";

const dataService = new DataService();
const he = require('he');

export default {
  name: "dashboard",
  components: {
    DashboardFilterWidgets, MongoQueryBuilder
  },
  props: {
    selectedValue: Object // Selected project
  },
  data() {
    return {
      // Loader
      loaderProgress: 0,
      loaderProgressBase: 0,
      loaderProgressFn: null,

      // Tabs
      active: null,
      loadedTabs: [],
      demographicTabId: null,

      projects: [],
      currentProject: [],
      selectedProject: "",
      dashboardVisible: false,
      projectSelectionVisible: true,
      filters: [],
      filterQuestions: [],
      categoryQuestions: [
        {
          name: "",
          questions: []
        }
      ],
      dashboardLoaded: false,
      invalidProject: false,
      distinctRespondentCount: 0,
      selectedBrand: "COMMON",
      commentsMap: new Map(),
      
      // advance Filter Questions
      useFilterType: 2, // 1: Basic, 2: Advance
      panels: [],
      advanceFilterQuestions: [],

      // Download project files
      openDownloadDialog: false,
      projectFiles: null,

      // Export config
      toExport: false,

      // Debug
      debug: this.$route.query.debug == "true" ? true : false
    };
  },
  beforeDestroy() {
    window.$cookies.remove('brand')
  },
  mounted() {
    this.handleProjectChange()
  },
  methods: {
    applyFilters() {
      dashboard.clearCount()
      this.distinctRespondentCount = 0
      this.loadedTabs = []
      this.loadTab(this.active)
    },

    loadTab(tabIndex) {
      // No tab has been loaded and no respondent count is set
      if (this.loadedTabs.length == 0) {
        this.demographicTabId && this.loadedTabs.push(this.demographicTabId)
        this.renderCharts(this.$refs.chartContainer.filter(c => c.dataset.tabIndex == this.demographicTabId))
      }

      // All loaded
      if (this.categoryQuestions.length == this.loadedTabs.length) {
        this.runExportFunction()
      }

      let loadedTabs = this.loadedTabs.filter(e => e == tabIndex)

      // Current tab is not loaded
      if (loadedTabs.length == 0) {
        this.loadedTabs.push(tabIndex)
        this.renderCharts(this.$refs.chartContainer.filter(c => c.dataset.tabIndex == tabIndex))

        setTimeout(function() {
          window.dispatchEvent(new Event("resize"));
        }, 500);
      }
    },

    webSocketSeviceFn({ projectId, toMatchQuery, frontendFiltering = false, token = null }) {
      this.loadedTabs = []
      this.useFilterType = (frontendFiltering == true) ? 1 : 2

      this.webSocketService = new WebSocket(process.env.VUE_APP_SOCKET_URL)
      this.webSocketService.addEventListener("message", res => {
        let data = res.data ? JSON.parse(res.data) : {}

        if (this.debug) {
          console.log(toMatchQuery)
        }

        if (data.uniqueId) {
          this.webSocketService.send(JSON.stringify({ projectId, toMatchQuery, frontendFiltering, token }))
        }

        if (data.result) {
          if (Object.keys(data.result).length >= 0) {
            this.processAndRenderChartData({ ...data.result, frontendFiltering, questionBase: data.questionBase })
            this.distinctRespondentCount = data.count
          } else {
            this.$clearLoader()
          }

          this.webSocketService.close()
        }

        if (data.error && data.error == true) {
          this.$store.state.dialogMessageTitle = "Error"
          this.$store.state.dialogMessageText = data.message
          this.$store.state.showDialogMessage = true

          this.$clearLoader()
          this.webSocketService.close()
        }
      })
    },

    processAndRenderChartData(chartData) {      
      this.dashboardVisible = true;
      this.dashboardLoaded = true;
      dashboard.setData({ ...chartData, debug: this.debug }, { ...this.currentProject, frontendFiltering: chartData.frontendFiltering });

      this.$nextTick(async function() {
        if (undefined != this.$refs.chartContainer && this.$refs.chartContainer.length > 0) {
          if (undefined != this.currentProject.brands && this.currentProject.brands.length > 0) {
              this.selectedBrand = this.currentProject.brands[0];

            if (undefined != this.selectedBrand && this.selectedBrand != "COMMON") {
              window.$cookies.set('brand', this.selectedBrand)
              this.filters.push({ key: "brand", value: [this.selectedBrand] });
            }
          }

          this.setComments();
          this.loadTab(this.active)
          this.$clearLoader();
        } else {
          this.distinctRespondentCount = 0;
          this.$clearLoader();
        }
      });
    },

    getDashboardData({ projectId, toMatchQuery = undefined, frontendFiltering = false }) {
      let token = window.$cookies.get("izcToken")

      if (token != null) {
        this.$setLoader();
        this.webSocketSeviceFn({ projectId, toMatchQuery, frontendFiltering, token })

        dataService.loadComments({
          organization_id: this.$store.state.buyerUser.organization_id,
          projectId
        }).then(r => {
          r.comments.forEach(c => { this.setComments(c) });
        });
      } else {
        alert("Session Expired. Please Login Again.")
        this.$router.push({ name: "Login" });   
      }
    },

    renderCharts(chartContainers) {
      this.loaderProgressBase += chartContainers.length

      for (let c of chartContainers) {
        this.$worker.run(arg => { return arg }, [ Object.assign({}, c.dataset) ]).then(result => {
          dashboard.renderChart(result, this.filters);
  
          this.loaderProgress += 1
          this.loaderProgressFn = setTimeout(this.setLoaderProgressExpired, 60000)

          if ((this.loaderProgress == this.loaderProgressBase) && this.loaderProgressBase > 0) {
            this.runExportFunction()
          }
            
          if (this.distinctRespondentCount == 0) {
            this.distinctRespondentCount = dashboard.getDistinctCount();
          }
        }).catch(e => {
          console.error(e);
        })
      }

      this.$clearLoader();
    },

    runExportFunction() {
      this.loaderProgress = 0
      this.loaderProgressBase = 0

      if (this.toExport == 'charts') {
        dashboard.exportCharts()
      } else if (this.toExport == 'data') {
        let hiddenElement = document.createElement('a');
        hiddenElement.href = dashboard.processAllDataInCSV()
        hiddenElement.target = '_blank'
        hiddenElement.download = `${this.currentProject.projectName}_chart_data.csv`
        hiddenElement.click();
      }

      this.toExport = false
    },

    setLoaderProgressExpired: function() {
      if (this.debug) {
        console.log("[Debug] Chart not able to load completely!")
      } else {
        this.loaderProgress = this.loaderProgressBase
      }
    },

    handleProjectChange: async function() {
      this.projectFiles = null
      this.dashboardVisible = false
      this.useFilterType = 2
      this.filters = []
      this.loadedTabs = []
      this.$setLoader()
      
      dashboard.destroy()
      window.$cookies.remove('brand')

      dataService.getSelectedProject(this.selectedValue).then(r =>{
        this.currentProject = r.data;

        if (this.debug) {
          console.log(this.currentProject)
        }

        this.processAdvanceFilterQuestions(this.currentProject)
        this.populateFilterQuestions();
        this.$clearLoader()
        this.getDashboardData({ projectId: this.selectedValue.projectId });
      })
    },

    processAdvanceFilterQuestions(project) {
      let filterQuestions = []

      for (let question of project.questions) {
        if (question.shown == true) {
          let options = []

          if (question.options && question.options.length > 0) {
            for (let option of question.options) {
              options.push({
                displayText: option.text ? he.decode(option.text).replace(/<\/?[^>]+(>|$)/g, " ") : option.text,
                answer: (project.filterKey && project.filterKey == "text") ? (option.text ? he.decode(option.text.replace(/"/g, '\\"')) : option.text) : option.decodedValue
              })
            }
          }

          filterQuestions.push({
            displayText: he.decode(question.display_text ? question.display_text : question.text).replace(/<\/?[^>]+(>|$)/g, " "),
            text: question.text ? he.decode(question.text).replace(/"/g, '\\"') : question.text,
            options
          })
        }
      }

      this.advanceFilterQuestions = filterQuestions
    },
 
    handleFilterChange: function(selectedValue, filterKey) {
      // check if the filter key exists or not.
      if ( this.filters.filter(function(f) { return f.key === filterKey }).length == 0) {
        this.filters.push({ key: filterKey, value: selectedValue });
      } else {
        if (selectedValue.length == 0) {
          const index = this.filters.findIndex(filter => filter.key === filterKey)

          if (index > -1) {
            this.filters.splice(index, 1);
          }
        } else {
          this.filters.filter(function(f) { return f.key === filterKey })[0].value = selectedValue;
        }
      }

      dashboard.clearCount()
    },

    populateFilterQuestions: function() {
      var filterQs = [];
      let categoryQuestionsMap = new Map();
      for (let q of this.currentProject.questions) {
        if (q.filter === true) {
          filterQs.push(q);
        }

        if (q.shown) {
          if (undefined != q.category && q.category.length > 0) {
            if (undefined == categoryQuestionsMap[q.category]) {
              categoryQuestionsMap[q.category] = {
                name: q.category,
                demographic: q.demographic ? q.demographic : false,
                questions: [],
              };
            }
            categoryQuestionsMap[q.category].questions.push(q);
          }
        }
      }

      this.categoryQuestions = [];
      for (let key of Object.keys(categoryQuestionsMap)) {
        if (undefined != categoryQuestionsMap[key].questions) {
          this.categoryQuestions.push(categoryQuestionsMap[key]);

          if (categoryQuestionsMap[key].demographic == true && this.demographicTabId == null) {
            this.demographicTabId = this.categoryQuestions.length - 1
          }
        }
      }

      this.filterQuestions = filterQs;
    },

    handleBrandChange: function() {
      this.filters = [];
      dashboard.clearCount()
      this.filters.push({ key: "brand", value: [this.selectedBrand] });
      window.$cookies.set('brand', this.selectedBrand)
      this.renderCharts(this.$refs.chartContainer);
    },
    
    handleSaveComment: function(event) {
      let commentText = document.getElementById("comment_" + event.currentTarget.dataset.questionid).value;

      if (commentText.trim().length > 0) {
        let comment = {
          commentText: commentText,
          question_id: event.currentTarget.dataset.questionid,
          project_id: this.selectedProject.projectId,
          createdBy: this.$store.state.userProfile.email,
          updatedBy: this.$store.state.userProfile.email,
          organization_id: this.$store.state.userProfile.organization_id,
          brand: this.selectedBrand,
          _id: event.currentTarget.dataset.id
        };

        this.$setLoader();
        dataService.saveComment(comment).then(() => {
          this.setComments(comment);
          this.$clearLoader();
        });
      }
    },

    setComments(comment) {
      if (undefined != comment) {
        if (undefined == this.commentsMap[comment.brand]) {
          this.commentsMap[comment.brand] = new Map();
        }

        if (undefined == this.commentsMap[comment.brand][comment.question_id]) {
          this.commentsMap[comment.brand][comment.question_id] = {
            _id: comment._id,
            comment: comment.commentText
          };
        }
      }
    },

    exportAll: function({ exportType }) {
      this.toExport = exportType ? exportType : false

      for (let i = 0; i < this.categoryQuestions.length; i++) {
        this.loadTab(i)
      }
    },

    onLoadProjectFiles: function() {
      this.openDownloadDialog = true

      if (this.projectFiles == null || this.projectFiles == undefined) {
        dataService.loadProjectFiles({ projectId: this.selectedValue.projectId }).then(data => {
          this.projectFiles = data
        })
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.hidden {
  display: none;
}

.graph-padding {
  padding-top: 25px;
}

.center {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 75%;
}
</style>
